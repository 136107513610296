<template>
  <v-list-item-avatar size="40" tile :title="item.pointType">
    <img v-if="iconUrl" :src="iconUrl" />

    <div style="position: absolute;" v-show="false">{{ item.id }}</div>
  </v-list-item-avatar>
</template>

<script>
/*https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_start-50x50.png { "id": 22, "name": "x_CampMarker_start", "src": "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_start-50x50.png", "height": 100, "width": 100 } start 1


          "icon-image": [
            "match",
            ["get", "pointType"],
            "start",
            "x_CampMarker_start", // Color for "start" points
            "finish",
            "x_CampMarker_finish", // Color for "finish" points
            "skipped",
            "x_CampMarker_skip", // Color for "start" points

            "closed",
            "x_CampMarker_closed", // Color for "campsite_closed" points
            "normal",
            "x_CampMarker_normal", // Color for "normal" points
            "x_CampMarker_skip" // Default color if "pointType" doesn't match any of the above
          ],
*/

//import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "campListIcon",

  data: () => ({
    iconLookup: {
      start: "x_CampMarker_start",
      finish: "x_CampMarker_finish",
      skipped: "x_CampMarker_skip",
      normal: "x_CampMarker_normal",
      closed: "x_CampMarker_closed"
    }
  }),

  mount() {},
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      iconlist: state => state.filters.iconlist
    }),
    iconUrl() {
      try {
        if (this.iconlist[this.iconLookup[this.item.pointType]]) {
          return this.iconlist[this.iconLookup[this.item.pointType]].src;
        } else {
          return null;
        }
      } catch (error) {
        //do nothing;
        return null;
      }
    }
  },
  methods: {},
  props: {
    item: Object
  }
};
</script>
<style scoped></style>
