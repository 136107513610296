const setup = require("./setup");
const filter = require("./filter");

const updateFilteredTack = state => {
  let filterOutput = filter.filterTrack(
    state.initalTrackLineString,
    state.campsitesConfig,
    state.fromCampOrderIndex,
    state.toCampOrderIndex,
    state?.skipCampById?.length ? state.skipCampById : [],
    state?.closedCampById?.length ? state.closedCampById : [],
    state?.customStart,
    state?.customEnd
  );

  state.filteredStats = filterOutput.filteredStats;
  state.filteredSites = filterOutput.filteredSites;
  state.filteredTrackLineString = filterOutput.filteredTrackLineString;
  state.filteredCampsitesGeoJson = filterOutput.filteredCampsitesGeoJson;

  state.elevationData = filterOutput.elevationData;
};

const getKeepOptions = (state, options) => {
  //this stores current setup to be applied after a reset;
  let keepOptions = {};
  let { fromCampOrderIndex, toCampOrderIndex, skipCampById } = state;

  let extra = {};

  let { oldOrderForward, newOrderForward } = options;

  if (oldOrderForward === true || oldOrderForward === false) {
    extra.oldOrderForward = oldOrderForward;
  }
  if (newOrderForward === true || newOrderForward === false) {
    extra.newOrderForward = newOrderForward;
  }

  keepOptions = {
    fromCampOrderIndex,
    toCampOrderIndex,
    skipCampById,
    ...extra
  };
  return keepOptions;
};

const setKeepOption = (state, keepOptions) => {
  let { campsitesConfig } = state;

  let trailLength = -1;
  try {
    trailLength = Object.keys(campsitesConfig).length;
  } catch (error) {
    //do nothing;
  }

  if (
    trailLength &&
    (keepOptions.oldOrderForward === true ||
      keepOptions.oldOrderForward === false) &&
    (keepOptions.newOrderForward === true ||
      keepOptions.newOrderForward === false) &&
    keepOptions.newOrderForward != keepOptions.oldOrderForward
  ) {
    //oldOrderForward set and newOrderForward set and they are different, so flip start and stop index;
    let stops = [keepOptions.fromCampOrderIndex, keepOptions.toCampOrderIndex];
    //if direction has flipped, selected campsite order will flip (first campsite order, will be last camp site order);
    state.fromCampOrderIndex = trailLength - stops[1] - 1;
    state.toCampOrderIndex = trailLength - stops[0] - 1;
  }

  state.skipCampById = keepOptions.skipCampById;
};

const rebuildDataSets = (state, options) => {
  let keepSetup = options?.keepSetup === true ? true : false;

  let keepOptions = keepSetup ? getKeepOptions(state, options) : {};

  let initDataOutput = setup.initData(
    JSON.parse(JSON.stringify(state.currentCampsites)),
    JSON.parse(JSON.stringify(state.currentTrack)),
    state.orderForward
  );

  state.closedCampById = initDataOutput.campsite_closedCampById;

  state.skipCampByIdDefualt = initDataOutput.campsite_skipByDefaultCampById;
  state.skipCampById = initDataOutput.campsite_skipByDefaultCampById;

  let { minCampOrderIndexVal, maxCampOrderIndexVal } = initDataOutput;

  //alwasys run twice, once without skip array to cache next camp site, once with skip array to calc next camp;
  let filterOutput = filter.filterTrack(
    initDataOutput.updatedTrackLineString,
    initDataOutput.campsitesConfig,
    minCampOrderIndexVal,
    maxCampOrderIndexVal,
    [],
    []
  );

  state.fromCampOrderIndex = initDataOutput.minCampOrderIndexVal;
  state.toCampOrderIndex = initDataOutput.maxCampOrderIndexVal;

  if (keepSetup === true) {
    setKeepOption(state, keepOptions);
  }

  if (
    state?.skipCampById?.length ||
    state?.closedCampById?.length ||
    keepSetup
  ) {
    filterOutput = filter.filterTrack(
      initDataOutput.updatedTrackLineString,
      initDataOutput.campsitesConfig,
      state.fromCampOrderIndex,
      state.toCampOrderIndex,
      state?.skipCampById?.length ? state.skipCampById : [],
      state?.closedCampById?.length ? state.closedCampById : []
    );
  }

  state.initalTrackLineString = initDataOutput.updatedTrackLineString;
  state.campsitesConfig = initDataOutput.campsitesConfig;
  state.initalCampsitesGeoJson = initDataOutput.initalCampsitesGeoJson;
  state.campsitesLookup = initDataOutput.campsitesLookup;
  state.campsitesOrderIndexArray = initDataOutput.campsitesOrderIndexArray;
  state.minCampOrderIndexVal = initDataOutput.minCampOrderIndexVal;
  state.maxCampOrderIndexVal = initDataOutput.maxCampOrderIndexVal;

  state.filteredStats = filterOutput.filteredStats;
  state.filteredSites = filterOutput.filteredSites;
  state.filteredTrackLineString = filterOutput.filteredTrackLineString;
  state.filteredCampsitesGeoJson = filterOutput.filteredCampsitesGeoJson;

  state.elevationData = filterOutput.elevationData;

  state.sectionSetLookup = setup.updateSectionSetLookup({
    sectionSet: state.sectionSet,
    campsitesLookup: initDataOutput.campsitesLookup
  });
};

module.exports.updateFilteredTack = updateFilteredTack;
module.exports.rebuildDataSets = rebuildDataSets;
