<template>
  <div class="mediaButtonsWrapper">
    <div class="pa-1 pb-0">
      <mediaButton
        :class="media_urls && media_urls.length ? 'pb-1' : ''"
        v-if="
          properties.acfCampsite &&
            properties.acfCampsite.hh_camp_link_campsite_link_mainsite &&
            properties.acfCampsite.hh_camp_link_campsite_link_mainsite.value &&
            properties.acfCampsite.hh_camp_link_campsite_link_mainsite.value.url
        "
        :width="width"
        :type="types[1]"
        :url="
          properties.acfCampsite.hh_camp_link_campsite_link_mainsite.value.url
        "
        title="More Info"
        markertitle="More Info"
      ></mediaButton>

      <mediaButton
        v-if="false && media_urls && media_urls.length"
        :width="width"
        :type="{
          prop: 'gallery',
          title: 'gallery',
          icon: 'mdi-image-multiple',
          single: 'Gallery'
        }"
        :gallery="media_urls"
        :title="'Gallery'"
        :markertitle="properties.title"
      ></mediaButton>
    </div>

    <div v-if="properties.attachment_urls && properties.attachment_urls.length">
      <div
        v-for="doc in properties.attachment_urls"
        :key="`attachment_${doc.slug}`"
      >
        <downloadButton class="pa-1 pb-0" :doc="doc"></downloadButton>
      </div>
    </div>

    <div v-if="acfMediaLength">
      <div v-for="type in types" :key="`section_${type.prop}`">
        <div v-for="(item, i) in acfMedia" :key="`${type.prop}_${i}`">
          <template v-if="type.acfType == item.type">
            <mediaButton
              class="pa-1 pb-0"
              v-if="item.value && item.value.url && type && type.prop"
              :width="width"
              :type="type"
              :url="item.value.url"
              :title="item.value.title"
              :markertitle="properties.title"
            ></mediaButton>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mediaButton from "./mediaButton";
import downloadButton from "./downloadButton";

export default {
  name: "mediaButtons",

  components: { mediaButton, downloadButton },

  data: () => ({
    types: [
      {
        acfType: "Photosphere",
        prop: "streetviews",
        title: "streetviews",
        icon: "mdi-web",
        single: "Photosphere"
      },
      {
        acfType: "weblink",
        prop: "links",
        title: "links",
        icon: "mdi-link",
        single: "Link"
      },
      {
        acfType: "videoLink",
        prop: "videos",
        title: "videos",
        icon: "mdi-youtube",
        single: "Video"
      }
    ]
  }),
  mounted() {},
  props: {
    width: Object,
    properties: Object,
    acfMedia: Object,
    media_urls: Array
  },
  methods: {},
  computed: {
    acfMediaLength() {
      let length = 0;

      try {
        length = Object.values(this.acfMedia);
      } catch (error) {
        //do nothing;
      }

      return length;
    }
  }
};
</script>

<style>
div.mediaButtonsWrapper {
  width: 100%;
}
</style>
