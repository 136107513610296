const turf = require("@turf/turf");

const fitlerSite = (campsitesConfig, orderIndex) => {
  let [site, ...other] = campsitesConfig.filter(row => {
    return row.orderIndex == orderIndex;
  });

  if (other) {
    //do nothing;
  }

  if (site) {
    return site;
  } else {
    return null;
  }
};

const convertToId = id => {
  try {
    if (id.length < 3) {
      id = ("00" + id).substr(-2);
    }
  } catch (error) {
    //do nothing
  }

  return id;
};

const getFromTo = (campsitesConfig, fromId, toId) => {
  let found = false;
  let ended = false;
  let output = [];
  campsitesConfig.forEach(row => {
    if (!found && row.orderIndex == fromId) {
      found = true;
    }
    if (found && !ended) {
      output.push(row);
      if (!ended && row.orderIndex == toId) {
        ended = true;
      }
    }
  });
  return output;
};

const getNewLinestring = (
  initalTrackLineString,
  fromCamp,
  toCamp,
  customStart,
  customEnd
) => {
  let unCustomLineString = JSON.parse(JSON.stringify(initalTrackLineString));

  initalTrackLineString = JSON.parse(JSON.stringify(initalTrackLineString));
  let fromIndex = fromCamp.intersectIndex;
  let toIndex = toCamp.intersectIndex;
  let fromIndexInital = fromCamp.intersectIndex;
  let toIndexInital = toCamp.intersectIndex;
  let hasBeenCutByCustom = false;

  let secCustStart = null;
  let secCustEnd = null;

  //this is to work out if the custom start or end will effect this ;
  let customStartIndex = !isNaN(customStart?.intersectIndex)
    ? customStart.intersectIndex
    : false;
  let customEndIndex = !isNaN(customEnd?.intersectIndex)
    ? customEnd.intersectIndex
    : false;

  if (!isNaN(customStartIndex)) {
    if (fromIndex < customStartIndex && customStartIndex < toIndex) {
      fromIndex = customStartIndex;
      hasBeenCutByCustom = true;
      secCustStart = true;
    }
  }

  if (!isNaN(customEndIndex)) {
    if (fromIndex < customEndIndex && customEndIndex < toIndex) {
      toIndex = customEndIndex;
      hasBeenCutByCustom = true;
      secCustEnd = true;
    }
  }

  try {
    initalTrackLineString.geometry.coordinates = initalTrackLineString.geometry.coordinates.filter(
      (point, index) => {
        return index >= fromIndex && index <= toIndex;
      }
    );
  } catch (error) {
    //do nothingk
  }
  let output = {
    lineString: initalTrackLineString,
    custLeg: { secCustStart, secCustEnd }
  };
  if (hasBeenCutByCustom) {
    try {
      unCustomLineString.geometry.coordinates = unCustomLineString.geometry.coordinates.filter(
        (point, index) => {
          return index >= fromIndexInital && index <= toIndexInital;
        }
      );

      if (
        JSON.stringify(initalTrackLineString) !==
        JSON.stringify(unCustomLineString)
      ) {
        output.unCustomLineString = unCustomLineString;
      }
    } catch (error) {
      //do nothingk
    }
  }

  return output;
};

const _filterTrack = (
  initalTrackLineString,
  campsitesConfig,
  fromId,
  toId,
  skipCampById,
  closeCampById,
  customStart,
  customEnd
) => {
  try {
    //dont skip start or finish;
    let fromSiteId = campsitesConfig[fromId].id;
    let toSiteId = campsitesConfig[toId].id;

    skipCampById = JSON.parse(JSON.stringify(skipCampById));
    closeCampById = JSON.parse(JSON.stringify(closeCampById));

    skipCampById = [...skipCampById, ...closeCampById];

    skipCampById = skipCampById.filter(
      row => row != fromSiteId && row != toSiteId
    );
    skipCampById = [...new Set(skipCampById)];
  } catch (error) {
    //do nothing;
  }

  try {
    let valid = false;
    if (
      initalTrackLineString &&
      campsitesConfig &&
      !isNaN(fromId) &&
      !isNaN(toId) &&
      campsitesConfig.length
    ) {
      valid = true;
    }

    if (!valid) {
      throw { error: true, title: "validation" };
    }

    let fromSite = fitlerSite(campsitesConfig, fromId);
    let toSite = fitlerSite(campsitesConfig, toId);

    if (!fromSite || !toSite) {
      throw { error: true, title: "sitesNotFound" };
    }

    let filteredSites = getFromTo(
      campsitesConfig,
      fromId,
      toId,
      customStart,
      customEnd
    );
    let { lineString: filteredTrackLineString, custLeg } = getNewLinestring(
      initalTrackLineString,
      fromSite,
      toSite,
      customStart,
      customEnd
    );

    if (custLeg) {
      //do nothing:
    }

    let filteredCampsitesGeoJson = {
      type: "FeatureCollection",
      features: [
        ...filteredSites.map(row => {
          try {
            row.point.id = row?.point?.properties.id;
          } catch (error) {
            //do nothing;
          }

          return {
            ...row.point
          };
        })
      ]
    };

    let skipCampsOrderIndex = [];
    filteredCampsitesGeoJson.features.forEach(row => {
      try {
        let { properties } = row;
        let { id, orderIndex } = properties;

        if (skipCampById.length && skipCampById.includes(String(id))) {
          skipCampsOrderIndex.push(orderIndex);
        }
      } catch (error) {
        //do nothing;
      }
    });

    getLegStats(
      initalTrackLineString,
      filteredCampsitesGeoJson,
      skipCampsOrderIndex,
      customStart,
      customEnd
    );

    let filteredStats = getTrackStats(
      filteredTrackLineString,
      filteredCampsitesGeoJson,
      fromSite,
      toSite,
      custLeg
    );

    let elevationData = getElevationStats(
      fromSite,
      filteredTrackLineString,
      filteredCampsitesGeoJson,
      customStart,
      customEnd
    );

    let finalizeOutput = finalizeTrack(
      filteredTrackLineString,
      filteredCampsitesGeoJson
    );
    filteredTrackLineString = finalizeOutput.filteredTrackLineString;
    filteredCampsitesGeoJson = finalizeOutput.filteredCampsitesGeoJson;

    return {
      filteredCampsitesGeoJson,
      filteredSites,
      filteredTrackLineString,
      filteredStats,
      elevationData
    };
  } catch (error) {
    //do nothing;
  }
};

const calculateDistRiseFall = geojson => {
  let rise = 0;
  let fall = 0;
  const coordinates = geojson.geometry.coordinates;

  for (let i = 1; i < coordinates.length; i++) {
    const elevationDiff = coordinates[i][2] - coordinates[i - 1][2];
    if (elevationDiff > 0) {
      rise += elevationDiff;
    } else {
      fall -= elevationDiff;
    }
  }
  let distance = turf.length(geojson);
  return { rise, fall, distance };
};

const getLegStats = (
  initalTrackLineString,
  filteredCampsitesGeoJson,
  skipCampsOrderIndex,
  customStart,
  customEnd
) => {
  let legs = [];
  let last = false;
  let first = true;
  filteredCampsitesGeoJson.features.forEach((camp, index) => {
    camp = JSON.parse(JSON.stringify(camp));
    let skip = false;

    let { intersectIndex, orderIndex, title, id } = camp.properties;

    let currentSiteObj = {
      intersectIndex,
      orderIndex,
      currentTripIndex: index,
      title,
      id
    };

    if (skipCampsOrderIndex.includes(orderIndex)) {
      skip = true;
    } else if (
      !first &&
      filteredCampsitesGeoJson.features.length - 1 != index &&
      skip
    ) {
      // do nothing;
    } else if (first) {
      last = currentSiteObj;
      first = false;
    } else {
      legs.push({ from: last, to: currentSiteObj });
      last = currentSiteObj;
    }
  });
  filteredCampsitesGeoJson.features.forEach(row => {
    row.properties.legLineString = null;
    row.properties.legStats = null;
  });

  legs.forEach(row => {
    let { from, to } = row;

    let {
      lineString: sectionLineString,
      unCustomLineString,
      custLeg
    } = getNewLinestring(
      initalTrackLineString,
      from,
      to,
      customStart,
      customEnd
    );

    let { rise, fall, distance } = calculateDistRiseFall(sectionLineString);

    let customDistRatio = null;
    try {
      if (unCustomLineString) {
        let { distance: rawDistance } = calculateDistRiseFall(
          unCustomLineString
        );
        customDistRatio = distance / rawDistance;
        customDistRatio = Math.round(customDistRatio * 1000) / 1000;
      }
    } catch (error) {
      //do nothing;
    }

    //row.sectionLineString = sectionLineString;

    distance = Math.round(distance * 10) / 10;
    rise = Math.round(rise * 10) / 10;
    fall = Math.round(fall * 10) / 10;

    filteredCampsitesGeoJson.features[
      from.currentTripIndex
    ].properties.legLineString = sectionLineString;

    let fromTitle = from.title;
    let toTitle = to.title;

    if (custLeg?.secCustStart || custLeg?.secCustEnd) {
      fromTitle = custLeg?.secCustStart ? "User-defined Start" : from.title;
      toTitle = custLeg?.secCustEnd ? "User-defined Finish" : to.title;
    }

    let legTitle = `${fromTitle} - ${toTitle}`;

    let legIdDlFile = `HH_Trail_${convertToId(from.id)}${
      custLeg?.secCustStart ? "a" : ""
    }_${convertToId(to.id)}${
      custLeg?.secCustEnd ? "a" : ""
    }_${fromTitle}-to-${toTitle}`;

    legIdDlFile = legIdDlFile.replace(/[/\\:*?"<>|]/g, "").replace(/\s+/g, "_");

    let acfTrack =
      filteredCampsitesGeoJson.features[from.currentTripIndex].properties
        ?.acfTrack;

    let legStats = {
      fromTitle,
      toTitle,
      distance,
      customDistRatio,
      rise,
      fall,
      legTitle,
      legIdDlFile,
      acfTrack,
      ...custLeg
    };

    filteredCampsitesGeoJson.features[
      from.currentTripIndex
    ].properties.legStats = {
      ...legStats
    };

    //storing inital linestring without skips;
    try {
      if (
        !filteredCampsitesGeoJson.features[from.currentTripIndex].properties
          .nextCampLineString
      ) {
        filteredCampsitesGeoJson.features[
          from.currentTripIndex
        ].properties.nextCampLineString = JSON.parse(
          JSON.stringify(sectionLineString)
        );
      }
    } catch (error) {
      //do nothing;
    }
    //storing inital nextCampStats;

    try {
      if (
        !filteredCampsitesGeoJson.features[from.currentTripIndex].properties
          .nextCampStats
      ) {
        filteredCampsitesGeoJson.features[
          from.currentTripIndex
        ].properties.nextCampStats = JSON.parse(JSON.stringify(legStats));
      }
    } catch (error) {
      //do nothing;
    }

    try {
      if (
        filteredCampsitesGeoJson.features[from.currentTripIndex + 1] &&
        filteredCampsitesGeoJson.features[from.currentTripIndex + 1]
          .properties &&
        !filteredCampsitesGeoJson.features[from.currentTripIndex + 1].properties
          .lastCampStats
      ) {
        filteredCampsitesGeoJson.features[
          from.currentTripIndex + 1
        ].properties.lastCampStats = JSON.parse(JSON.stringify(legStats));
      }
    } catch (error) {
      //do nothing;
    }
  });

  filteredCampsitesGeoJson.features.forEach((camp, index) => {
    let { orderIndex } = camp.properties;

    if (
      orderIndex != 0 &&
      filteredCampsitesGeoJson.features.length - 1 != index &&
      skipCampsOrderIndex.includes(orderIndex)
    ) {
      camp.properties.legSkip = true;
    } else {
      camp.properties.legSkip = false;
    }
  });
};

const finalizeTrack = (filteredTrackLineString, filteredCampsitesGeoJson) => {
  //note campsite icon
  let first = true;
  filteredCampsitesGeoJson.features.forEach((row, index) => {
    row.properties.pointType = "normal";
    if (row.properties.legSkip) {
      row.properties.pointType = "skipped";
      if (row?.properties?.campsite_closed) {
        row.properties.pointType = "closed";
      }
    }
    if (first) {
      row.properties.pointType = "start";
    }
    if (index + 1 == filteredCampsitesGeoJson.features.length) {
      row.properties.pointType = "finish";
    }
    first = false;
  });

  //Add camp day count;

  let day = 1;
  filteredCampsitesGeoJson.features.forEach(row => {
    row.properties.journeyDay = day;

    let titleExtra = "";

    if (row.properties.pointType === "start") {
      //do nothing;
    } else if (row.properties.pointType === "normal") {
      day++;
    } else if (row.properties.pointType === "skipped") {
      titleExtra = " (Skipped)";

      //do nothing;
    } else if (row.properties.pointType === "finish") {
      //do nothing;
    } else if (row.properties.pointType === "closed") {
      //do nothing;
      titleExtra = " (Closed)";
    }

    //add Tool tip/lable title;

    row.properties.toolTipTitle = `${row.properties.title}${titleExtra}`;
  });

  return { filteredTrackLineString, filteredCampsitesGeoJson };
};

const getElevationStats = (
  fromSite,
  filteredTrackLineString,
  filteredCampsitesGeoJson,
  customStart,
  customEnd
) => {
  /*
  console.log("getElevationStats", {
    fromSite,
    filteredTrackLineString,
    filteredCampsitesGeoJson,
    customStart,
    customEnd
  });
*/
  let { intersectIndex: offSet } = fromSite;

  if (customStart?.intersectIndex) {
    offSet = customStart.intersectIndex;
  }

  let lineStringDistElevationArray = [];
  let campsiteDistElevationArray = [];

  let lineString = filteredTrackLineString.geometry.coordinates;
  try {
    let lastPoint = lineString[0];
    if (lastPoint && lastPoint[2]) {
      let lastPointObj = turf.point(lastPoint);
      let runningDistance = 0;

      lineString.forEach((point, index) => {
        let elevation = point[2];
        let currentPointObj = turf.point(point);
        var distance = turf.distance(lastPointObj, currentPointObj, "meters");

        runningDistance = runningDistance + distance;

        lineStringDistElevationArray.push({
          i: index,
          e: elevation,
          d: Math.round(runningDistance * 100) / 100
          //x: point[0], prod dont need this..
          //y: point[1]
        });
        lastPoint = point;
        lastPointObj = currentPointObj;
      });
      filteredCampsitesGeoJson.features.forEach(campsite => {
        let { intersectIndex, title } = campsite.properties;
        let campSiteDetails = {
          ...lineStringDistElevationArray[intersectIndex - offSet]
        };
        campSiteDetails.title = title;

        campsiteDistElevationArray.push(campSiteDetails);
      });
    }
  } catch (error) {
    //do nothing;
  }

  try {
    if (customStart) {
      let custStartDetails = {
        ...lineStringDistElevationArray[0],
        title: "Custom Start"
      };
      campsiteDistElevationArray.push(custStartDetails);
    }

    if (customEnd) {
      let custEndDetails = {
        ...lineStringDistElevationArray[
          lineStringDistElevationArray.length - 1
        ],
        title: "Custom End"
      };
      campsiteDistElevationArray.push(custEndDetails);
    }
  } catch (error) {
    //do nothing;
  }

  return campsiteDistElevationArray.length &&
    lineStringDistElevationArray.length
    ? { campsiteDistElevationArray, lineStringDistElevationArray }
    : {};
};

const getTrackStats = (
  filteredTrackLineString,
  filteredCampsitesGeoJson,
  from,
  to,
  custLeg
) => {
  filteredCampsitesGeoJson = JSON.parse(
    JSON.stringify(filteredCampsitesGeoJson)
  );
  filteredCampsitesGeoJson.features = filteredCampsitesGeoJson.features.filter(
    camp => {
      return !camp?.properties?.legSkip;
    }
  );
  let filteredTrackLength = turf.length(filteredTrackLineString, {
    units: "kilometers"
  });
  let days = filteredCampsitesGeoJson.features.length - 1;
  let nights = filteredCampsitesGeoJson.features.length - 2;
  let average = Math.round((filteredTrackLength / days) * 10) / 10;
  filteredTrackLength = Math.round(filteredTrackLength * 10) / 10;

  let eachDaysTrip = filteredCampsitesGeoJson.features.map(row => {
    return row?.properties?.legSkip?.distance
      ? row.properties.legSkip.distance
      : row.properties.nextDist;
  });
  eachDaysTrip.length = eachDaysTrip.length - 1;

  let fromTitle = from.title;
  let toTitle = to.title;

  if (custLeg?.secCustStart || custLeg?.secCustEnd) {
    fromTitle = custLeg?.secCustStart ? "User-defined Start" : from.title;
    toTitle = custLeg?.secCustEnd ? "User-defined Finish" : to.title;
  }

  let journeyTitle = `${fromTitle} - ${toTitle}`;
  let journeyTitleId = `${fromTitle}(${from.id}) ${toTitle}(${to.id})`;
  let journeyIdDlFile = `HH_Trail_${convertToId(from.id)}${
    custLeg?.secCustStart ? "a" : ""
  }_${convertToId(to.id)}${
    custLeg?.secCustEnd ? "a" : ""
  }_${fromTitle}-to-${toTitle}`;

  journeyIdDlFile = journeyIdDlFile
    .replace(/[/\\:*?"<>|]/g, "")
    .replace(/\s+/g, "_");

  let filteredStats = {
    distance: filteredTrackLength,
    days,
    nights,
    average,
    minDist: Math.min(...eachDaysTrip),
    maxDist: Math.max(...eachDaysTrip),

    journeyTitle,
    journeyTitleId,
    journeyIdDlFile,
    fromId: from.id,
    toId: to.id,
    fromTitle,
    toTitle,

    toGeom: { geometry: to?.point?.geometry, type: "Feature" },
    fromGeom: { geometry: from?.point?.geometry, type: "Feature" }
  };

  return filteredStats;
};

module.exports.filterTrack = _filterTrack;
