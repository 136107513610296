<template>
  <div>
    <div
      ref="noteTextContent"
      :class="{
        clampFourLines: !noteIsExpanded,
        campText: true,
        'mt-1': true
      }"
      v-if="content"
    >
      <div v-html="content"></div>
    </div>
    <button v-if="content && isOverflowing" @click="toggleText">
      <b> {{ noteIsExpanded ? "Show Less" : "Show More" }}</b>
    </button>
  </div>
</template>

<script>
//import { eventBus } from "../../main";

export default {
  name: "popupContentBlock",

  data: () => ({
    noteIsExpanded: false,
    isOverflowing: false
  }),

  mounted() {
    if (this?.properties?.iconcategory === "Short Walks") {
      this.noteIsExpanded = true;
    }

    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow); // Re-check overflow on resize
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkOverflow);
  },

  methods: {
    checkOverflow() {
      if (this.whereShow === "popup") {
        if (this?.content) {
          const noteTextContent = this.$refs.noteTextContent;
          this.isOverflowing =
            noteTextContent.scrollHeight > noteTextContent.clientHeight;
        }
      }
    },
    toggleText() {
      this.noteIsExpanded = !this.noteIsExpanded;
    },

    checkShow(key, data) {
      let show = true;
      if ((key, data)) {
        //do nothing;
      }

      if (this.whereShow === "popup") {
        //do nothing;
        if (this.hideIfNoPopup[key] && data?.value === "No") {
          show = false;
        }
      }

      return show;
    }
  },
  props: {
    properties: Object,
    content: String,
    whereShow: String
  }
};
</script>
<style scoped>
.peramItem {
  width: 100%;
  width: auto;
  padding-right: 10px;
}
.peramItem i {
  width: 32px;
}

.campText {
  line-height: 1.5em; /* Line height to match */
}

.clampFourLines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4; /* Limits to 4 lines */
  max-height: 6em; /* Adjust as needed based on line-height */
  line-height: 1.5em; /* Line height to match */
}

.compactList {
  line-height: 1.32;
}
</style>
