<template>
  <div max-width="450" class="mx-auto">
    <div class="campsiteListWrapper">
      <journeyStats
        v-if="filteredStats && filteredStats.fromTitle"
        class="pa-2"
        :iteminfo="filteredStats"
      ></journeyStats>

      <div v-show="false">
        <tripReport></tripReport>

        <elevationGraph
          v-if="elevationData && elevationData.campsiteDistElevationArray"
        ></elevationGraph>
      </div>
      <div
        class="d-flex flex-row justify-space-between mx-2"
        style="    height: 36px;"
      >
        <v-btn tile color="primary" dark @click="loadTripReportDialog()">
          Trip Report
        </v-btn>

        <v-btn
          v-if="elevationData && elevationData.campsiteDistElevationArray"
          tile
          color="primary"
          dark
          @click="loadElevationGraphDialog()"
        >
          Elevation Plot
        </v-btn>
      </div>

      <br />
      <v-list>
        <template v-for="(item, index) in filteredCampsitesArray">
          <v-list-item
            dense
            :key="item.id + ' ' + index"
            style="min-height:34px"
          >
            <a @click="openPopup(item.id)">
              <campListIcon :item="item"></campListIcon>
            </a>
            <v-list-item-content
              ><a @click="openPopup(item.id)">
                <v-list-item-title class=" subtitle-2 font-weight-medium">
                  <font v-if="item.pointType == 'start' && customStart">
                    Custom Start
                  </font>

                  <font v-else-if="item.pointType == 'finish' && customEnd">
                    Custom Start
                  </font>

                  <font v-else> {{ item.title }} </font>

                  <font
                    class="text-capitalize black--text"
                    v-if="
                      item.pointType != 'finish' &&
                        item.pointType != 'start' &&
                        item.legSkip
                    "
                  >
                    {{ item.campsite_closed ? "-Closed" : "-Skipped" }}
                  </font>

                  <font
                    class="text-capitalize black--text"
                    v-show="
                      item.pointType == 'finish' || item.pointType == 'start'
                    "
                  >
                    - {{ item.pointType }}
                  </font>
                </v-list-item-title>
              </a>

              <v-list-item-subtitle
                v-if="item.pointType != 'finish' && !item.legSkip"
              >
                <SideBarLegStats
                  :day="item.journeyDay + (item.pointType != 'start' ? 1 : 0)"
                  :compact="true"
                  :iteminfo="item.legStats"
                ></SideBarLegStats>
              </v-list-item-subtitle>

              <v-list-item-subtitle v-if="item.pointType == 'finish'">
                Finish
              </v-list-item-subtitle>
            </v-list-item-content>

            <ListSubMenu
              :item="item"
              :skipCampById="skipCampById"
              :functions="{ openPopup, handleSkipCheckboxChange }"
            ></ListSubMenu>
          </v-list-item>
        </template>
      </v-list>
      <center>
        <downloadGpxButton
          class="mr-4 mb-2 "
          icon="mdi-download-box"
          title="Download Journey GPX"
          :report="true"
          :geojson="filteredTrackLineString"
          :legTitle="filteredStats.journeyIdDlFile"
        ></downloadGpxButton>
      </center>
    </div>
  </div>
</template>

<script>
//import { eventBus } from "../../main";
import { mapState } from "vuex";
import { eventBus } from "./../../main";

import campListIcon from "./campListIcon.vue";
import ListSubMenu from "./ListSubMenu.vue";

import downloadGpxButton from "./../ui/downloadGpxButton.vue";
import elevationGraph from "./../elevation/elevationGraph.vue";

import tripReport from "./../report/tripReport.vue";
import journeyStats from "./../infoblocks/journeyStats";
import SideBarLegStats from "./../infoblocks/SideBarLegStats.vue";

export default {
  name: "CampSiteSideList",
  components: {
    campListIcon,
    ListSubMenu,
    downloadGpxButton,
    elevationGraph,
    tripReport,
    journeyStats,
    SideBarLegStats
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      elevationData: state => state.trail.elevationData,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      filteredTrackLineString: state => state.trail.filteredTrackLineString,
      filteredStats: state => state.trail.filteredStats,
      customStart: state => state.trail.customStart,
      customEnd: state => state.trail.customEnd
    }),

    skipCampById: {
      get() {
        return this.$store.state.trail.skipCampById;
      },
      set(value) {
        this.$store.dispatch("trail_update_skiplist_set", value);
      }
    },

    filteredCampsitesArray() {
      let array = this.filteredCampsitesGeoJson.features.map(row => {
        return row.properties;
      });

      return array;
    }
  },
  created() {},
  methods: {
    loadElevationGraphDialog() {
      eventBus.$emit("loadElevationGraphDialog");
    },
    loadTripReportDialog() {
      eventBus.$emit("loadTripReportDialog");
    },

    handleSkipCheckboxChange(id) {
      let isChecked = !this.skipCampById.includes(id);

      if (isChecked) {
        // Add id to the array if checked
        this.skipCampById.push(id);
      } else {
        // Remove id from the array if unchecked
        const index = this.skipCampById.indexOf(id);
        if (index > -1) {
          this.skipCampById.splice(index, 1);
        }
      }
      this.$store.dispatch("trail_update_skiplist_set", this.skipCampById);

      eventBus.$emit("map_clearAllPopups");
    },

    openPopup(id) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu", false);
      }

      eventBus.$emit("map_openCampSitePopupById", id);
    }
  }
};
</script>

<style>
#topOfHelpSection img {
  max-width: 80%;
}

.campsiteListWrapper,
.shortWalksWrapper {
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}

.checkbox-btn {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 8px;
  min-width: 80px; /* Optional: Adjust to suit your design */

  width: 168px;
  flex: none;
}

.checkbox-btn--checked {
  background-color: #ff6262; /* Checked background color (Green) */
  color: white; /* Checked text color */
}

.checkbox-btn--unchecked {
  background-color: white; /* Unchecked background color */
  color: black; /* Unchecked text color */
}

.checkbox-btn .v-icon {
  margin-right: 8px;
}
</style>
