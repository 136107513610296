// import Vue from 'vue'
//import queryString from 'query-string';
//const queryString = require("qs");
//const _ = require("lodash");

/*
let initalQueryStringObject = {};

try {
  let url = new URL(document.location.toString());
  let search = url.search;

  if (search.substring(0, 1) == "?") {
    search = search.substring(1);
  }

  var queryStringObject = queryString.parse(search);

  let decoded = {};
  _.forEach(pathKey, function(startKey, endKey) {
    let value = queryStringObject[startKey];

    try {
      value = parseFloat(value);
    } catch (error) {
      //do nothing;
    }

    if (!isNaN(value)) {
      decoded[endKey] = value;
    }
  });

  initalQueryStringObject = decoded;
} catch (error) {
  //do nothing;
  console.error("error decoding qs");
}
*/
const getIsDevEnviroment = () => {
  let isDev = false;
  let host = window.location.host;

  try {
    host = host.toLocaleLowerCase();

    if (host.includes("-dev") || host.includes("localhost")) {
      isDev = true;
    }
  } catch (error) {
    //do nothing;
  }

  return isDev;
};

/*

const pathKey = {
  zoom: "z",
  centerLat: "lat",
  centerLng: "lng",
  themeIndex: "t",
  layerIndex: "l",
  infoWindowTargetLat: "ilat",
  infoWindowTargetLng: "ilng"
};


const clearUrlPath = function() {
  var blankUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: blankUrl }, "", blankUrl);
};

if (clearUrlPath) {
  //do nothing;
}

const updatePath = state => {
  try {
    let keys = Object.keys(state.pathKey);
    let pathObject = {};
    keys.forEach(key => {
      let value = state[key];
      let pathKey = state.pathKey[key];
      if (value || value === 0) {
        pathObject[pathKey] = value;
      }
    });

    const pathQueryStringValue = queryString.stringify(pathObject);

    if (state) {
      //do nothig;
    }

    //let oldId = this.$route;
    //if (newId && newId !== oldId) {

    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${pathQueryStringValue}`;

    window.history.pushState({ path: newurl }, "", newurl);
  } catch (error) {
    console.error("error updaging qs");
  }
};

const updatePath_Debounce = _.debounce(
  function() {
    updatePath(stateCopy);
  },
  1000,
  {
    maxWait: 4000
  }
);

var stateCopy = null;

const buildPath = state => {
  if (state.readyToUpdatePath === true) {
    stateCopy = state;
    //updatePath_Debounce();
  } else {
    //do nothing;
  }
};
*/
const roundLl = value => {
  let roundValue = 1000000;
  return Math.round(value * roundValue) / roundValue;
};

const getters = {};
const state = {
  isDevEnviroment: getIsDevEnviroment(),
  mobileShowFilters: false,
  mobileShowSuburbList: false,

  readyToUpdatePath: false,

  //initalQueryStringObject,
  //pathKey,

  zoom: false,
  centerLat: false,
  centerLng: false,
  infoWindowTargetLat: false,
  infoWindowTargetLng: false,
  themeIndex: false,
  layerIndex: false
};
const mutations = {
  UPDATE_MOBILE_FILTER_SHOW(state, newValue) {
    state.mobileShowFilters = newValue;
  },
  UPDATE_MOBILE_SUBURB_SHOW(state, newValue) {
    state.mobileShowSuburbList = newValue;
  },

  UPDATE_READY_TO_UPDATE_PATH(state, newValue) {
    state.readyToUpdatePath = newValue;
    //buildPath(state);
  },

  UPDATE_ZOOM(state, newValue) {
    state.zoom = newValue;
    //buildPath(state);
  },
  UPDATE_CENTER(state, newValue) {
    let { lat, lng } = newValue;

    lat = roundLl(lat);
    lng = roundLl(lng);
    state.centerLat = lat ? lat : false;
    state.centerLng = lng ? lng : false;
    //buildPath(state);
  },
  UPDATE_INFO_WINDOW_TARGET(state, newValue) {
    let { lat, lng } = newValue;

    lat = roundLl(lat);
    lng = roundLl(lng);
    state.infoWindowTargetLat = lat ? lat : false;
    state.infoWindowTargetLng = lng ? lng : false;
    //buildPath(state);
  },
  UPDATE_THEME_INDEX(state, newValue) {
    state.themeIndex = newValue;
    //buildPath(state);
  },
  UPDATE_LAYER_INDEX(state, newValue) {
    state.layerIndex = newValue;
    //buildPath(state);
  }
};
const actions = {
  navUpdate_updateMobileFilterShow({ commit }, value) {
    commit("UPDATE_MOBILE_FILTER_SHOW", value);
  },
  navUpdate_updateMobileSuburbShow({ commit }, value) {
    commit("UPDATE_MOBILE_SUBURB_SHOW", value);
  },

  navUpdate_readyToUpdatePath({ commit }, value) {
    commit("UPDATE_READY_TO_UPDATE_PATH", value);
  },
  navUpdate_zoom({ commit }, value) {
    commit("UPDATE_ZOOM", value);
  },
  navUpdate_center({ commit }, value) {
    commit("UPDATE_CENTER", value);
  },
  navUpdate_infoWindowTarget({ commit }, value) {
    commit("UPDATE_INFO_WINDOW_TARGET", value);
  },
  navUpdate_themeIndex({ commit }, value) {
    commit("UPDATE_THEME_INDEX", value);
  },
  navUpdate_layerIndex({ commit }, value) {
    commit("UPDATE_LAYER_INDEX", value);
  },
  navUpdate_clearQueryStringPath() {
    //clearUrlPath();
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
