//import _, { initial } from "lodash";
import { mapState } from "vuex";

//import { eventBus } from "./../../../../main";

export default {
  data: () => ({
    campExtra_markers: [],
    campExtra_shortList: [
      "Showers",
      "Toilets",
      "Water Treated",
      "Water Untreated",
      "Shelters"
    ]
  }),

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      filteredCampsitesGeoJson: state => state.trail.filteredCampsitesGeoJson,
      iconcategory: state => state.mapsetupdate.iconcategory
    }),
    iconcategorySorted() {
      let output = this.iconcategory.map(row => {
        let { id, name, slug, catimage } = row;
        return { id, name, slug, small_marker: catimage.small_marker };
      });

      output = output.filter(
        row => row.small_marker && this.campExtra_shortList.includes(row.name)
      );
      return output;
    }
  },

  methods: {
    campExtra_clearLayer() {
      this.campExtra_markers.forEach(marker => marker.remove());
      this.campExtra_markers = [];
    },

    campExtra_getIcons(properties) {
      let { acfAmenities } = properties;

      let hasIcons = [];

      try {
        if (acfAmenities?.hh_am_yesno_toilet?.value === "Yes") {
          hasIcons.push("Toilets");
        }
      } catch (error) {
        //do nothing;
      }
      /*
      try {
        if (acfAmenities?.hh_am_yesno_shelter?.value != "No") {
          hasIcons.push("Shower");
        }
      } catch (error) {
        //do nothing;
      }
        */

      try {
        if (acfAmenities?.hh_am_yesno_shelter?.value != "No") {
          hasIcons.push("Shelters");
        }
      } catch (error) {
        //do nothing;
      }
      try {
        if (acfAmenities?.hh_am_multiselect_water?.value.includes("Tap")) {
          hasIcons.push("Water Treated");
        } else if (
          acfAmenities?.hh_am_multiselect_water?.value.includes("No")
        ) {
          //do nothing;
        } else if (acfAmenities?.hh_am_multiselect_water?.value) {
          hasIcons.push("Water Untreated");
        }
      } catch (error) {
        //do nothing;
      }

      /*
      [
        "Showers",
        "Toilets",
        "Water Treated",
        "Water Untreated",
        "Shelters"
      ]
*/

      return this.iconcategorySorted
        .filter(row => hasIcons.includes(row.name))
        .map(row => {
          row.html = `
<div class="mapTagIcon" title="${row.name}">
  <img src="${row.small_marker}">
</div>
`;

          return row;
        });
    },
    campExtra_drawLayer() {
      // Clear existing markers before adding new ones
      this.campExtra_clearLayer();

      // Loop through each feature in the GeoJSON data
      this.filteredCampsitesGeoJson.features.forEach(feature => {
        const { coordinates } = feature.geometry;
        const iconSets = this.campExtra_getIcons(feature.properties);

        // Create a new HTML marker
        const el = document.createElement("div");
        el.className = "custom-marker";
        el.innerHTML = `
<div class="campSiteTagSetWrapper">
<div class="campSiteTagSet">
${iconSets.map(row => row.html).join("")}

</div>
</div>
`;

        // Create a Mapbox marker
        const marker = new window.mapboxgl.Marker(el)
          .setLngLat(coordinates)
          .setOffset([0, -30])
          .addTo(this.map);

        // Store marker for future removal
        this.campExtra_markers.push(marker);
      });
    }
  },
  destroyed() {},
  mounted() {},
  watch: {}
};
