<template>
  <v-dialog
    v-model="dialog"
    class="wpdataHelp"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="600px"
  >
    <v-card>
      <v-toolbar dark dense flat color="topMenu">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ this.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="scrollable-content bodyText py-4">
        <div v-html="help.content"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";

export default {
  name: "helpDialog",
  props: {
    title: String,
    slug: String
  },
  data() {
    return {
      dialog: false
    };
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      pages: state => state.mapsetupdate.pages
    }),
    help() {
      let helpPost = this.pages.filter(row => (row.slug = this.slug))[0];
      return helpPost;
    }
  },

  destroyed() {
    eventBus.$off("load_" + this.slug + "Dialog");
  },
  mounted() {
    eventBus.$on("load_" + this.slug + "Dialog", () => {
      this.dialog = true;
    });
  }
};
</script>

<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}

.bodyText h1 {
  font-size: 20px;
  font-weight: 500;
}

.bodyText h2 {
  font-size: 17px;
  font-weight: 500;
}

.bodyText h3 {
  font-size: 15px;
  font-weight: 600;
}

.bodyText h4,
.bodyText h5,
.bodyText h6 {
  font-weight: 600;
}

.scrollable-content {
  max-height: 60vh;
  max-height: calc(var(--doc-height) - 150px);

  overflow-y: auto;
}

.isTabletOrMobile .scrollable-content {
  max-height: 60vh;
  max-height: calc(var(--doc-height) - 80px);

  overflow-y: auto;
}
</style>
