const getters = {};
const mutations = {
  UPDATE_MAPBOX_SETUP(state, newValue) {
    state.mapboxSetup = newValue;
  },
  UPDATE_POI(state, newValue) {
    state.poiItemsGeoJson = {
      type: "FeatureCollection",
      features: newValue
    };
  },
  UPDATE_LEGEND(state, newValue) {
    state.legendItems = newValue;
  },

  UPDATE_PAGES(state, newValue) {
    state.pages = newValue;
  },

  UPDATE_POSTS(state, newValue) {
    state.posts = newValue;
  },
  UPDATE_TAXONOMY(state, newValue) {
    state.taxonomy = newValue;
    state.iconcategory = newValue.iconcategory;
  },
  UPDATE_ALLPOSTS(state, newValue) {
    state.allposts = newValue;
  },
  UPDATE_WP_OFFLINE_MODE(state, newValue) {
    state.wpIsOffLineData = newValue;
  },
  UPDATE_WP_READY(state) {
    state.wpready = true;
  }
};

const actions = {
  setup_set_wpdata({ commit }, value) {
    commit("UPDATE_POI", value?.geoDataObject?.poi);
    commit("UPDATE_LEGEND", value?.nonGeoDataObject?.legend);
    commit("UPDATE_PAGES", value?.nonGeoDataObject?.pages);
    commit("UPDATE_POSTS", value?.nonGeoDataObject?.posts);
    commit("UPDATE_TAXONOMY", value?.taxonomy);
    commit("UPDATE_ALLPOSTS", value?.post);

    commit("UPDATE_MAPBOX_SETUP", value?.settings?.mapboxsetup);

    commit("UPDATE_WP_OFFLINE_MODE", value?.isOffLineData ? true : false);

    commit("UPDATE_WP_READY");
  }
};
const state = {
  wpIsOffLineData: false,
  wpready: false,
  mapboxSetup: {},
  legendItems: {},
  poiItemsGeoJson: {},
  pages: {},
  posts: {},
  taxonomy: {},
  iconcategory: {},
  allposts: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};
