const wpPull = require("./modules/wpPull.js");

const cachedData = require("./cachedData/cachedData.json");
const pako = require("pako");

const useCacheData = false;

const _loadWpMapData = async function() {
  let output = null;

  if (!useCacheData) {
    try {
      output = await wpPull.getData();

      const domain = `${window.location.host}`;

      if (
        domain.includes("localhost") ||
        domain.includes("hhdev-tripplanner")
      ) {
        try {
          //const atob = window.atob;

          let data = JSON.stringify(output);

          let outputCompressed = {
            z: window.btoa(pako.gzip(data, { to: "string" }))
          };

          console.log("======cmsLoadedData-----------");
          console.log({ output });
          console.log("======cmsLoadedData-----------");

          console.log("======outputCompressedData-----------");
          console.log({ outputCompressed });
          console.log("======outputCompressedData-----------");

          /*
          let outputUncompressed = JSON.parse(
            pako.ungzip(window.atob(outputCompressed.z), {
              to: "string"
            })
          );
*/
          //output = outputUncompressed;
        } catch (error) {
          console.error("error compressing local data", { error });
        }
      }
    } catch (error) {
      console.error("error loading wp data", { error });
    }
  }

  try {
    if (useCacheData || !output) {
      let outputUncompressed = JSON.parse(
        pako.ungzip(window.atob(cachedData.z), {
          to: "string"
        })
      );
      output = outputUncompressed;
      output.isOffLineData = true;

      console.error("==============dataLoadError================");
      console.error("==============dataLoadError================");
      console.error(
        "There has been an error loading data from the CMS.  Old version of the data is being used."
      );
      console.error("==============dataLoadError================");
      console.error("==============dataLoadError================");
    }
  } catch (error) {
    console.error("Error loading fallback data");
  }
  return output;
};

module.exports.loadWpMapData = _loadWpMapData;
