<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{}"> </template>
      <v-card color="topMenu">
        <v-card-title class="text-h5 white--text" v-if="!finished">
          Loading..
        </v-card-title>
        <v-card-title class="text-h5 white--text" v-else-if="isOffLineData">
          Connection Issue
        </v-card-title>
        <div>
          <template v-if="!finished">
            <v-card-text class="white--text"
              >Map is loading, Please wait.</v-card-text
            >

            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear
          ></template>

          <template v-if="isOffLineData && finished">
            <v-card-text class="white--text">
              The map is currently experiencing a connection issue, and some
              information is being displayed from a backup copy. This means
              certain details may be outdated. Please double-check critical
              information and check back soon for the most up-to-date data.
              Thank you for your patience!
            </v-card-text>
            <v-card-text class="white--text">
              <b>Details:</b>
              <font v-if="wpIsOffLineData">
                Latest campsite details, track details and POIs failed to load.
              </font>
              <font v-if="mbIsOffLineData">
                Latest track coordinates failed to load.
              </font>
            </v-card-text>
          </template>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-show="isOffLineData && finished"
            color="white"
            @click="dialog = false"
          >
            I Understand
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const _ = require("lodash");

import { eventBus } from "../../main";

import { mapState } from "vuex";

export default {
  name: "loadingScreen",
  data() {
    return {
      dialog: true,
      finished: false
    };
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      wpready: state => state.mapsetupdate.wpready,
      mbready: state => state.trail.mbready,
      wpIsOffLineData: state => state.mapsetupdate.wpIsOffLineData,
      mbIsOffLineData: state => state.trail.mbIsOffLineData
    }),

    isOffLineData() {
      return this.mbIsOffLineData || this.wpIsOffLineData;
    }
  },

  destroyed() {
    eventBus.$off("load_LoadingScreen");
  },
  mounted() {
    if (
      this.wpready == true &&
      this.mbready == true &&
      this.isOffLineData != true
    ) {
      this.dialog = false;
    }

    if (this.wpready == true && this.mbready == true) {
      this.finished = false;
    }

    this.updateValue();

    eventBus.$on("load_LoadingScreen", () => {
      this.dialog = true;
      this.finished = false;
    });
  },
  methods: {
    updateValue: _.debounce(function() {
      setTimeout(() => {
        if (
          this.wpready == true &&
          this.mbready == true &&
          this.isOffLineData != true
        ) {
          this.finished = true;
          this.dialog = false;
        } else if (this.wpready == true && this.mbready == true) {
          this.finished = true;
        }
      }, 1500);
    }, 200)
  },

  watch: {
    wpready() {
      this.updateValue();
    },
    mbready() {
      this.updateValue();
    },
    isOffLineData() {
      this.updateValue();
    }
  }
};
</script>
