<template>
  <div class="d-print-none" :class="{ drawOpen: drawerLeft }">
    <v-navigation-drawer
      touchless
      v-model="drawerLeft"
      app
      left
      :width="
        $vuetify.breakpoint.smAndUp
          ? appSettings.drawWidth
          : appSettings.mobDrawWidth
      "
    >
      <sidebar />
    </v-navigation-drawer>

    <v-app-bar
      dense
      flat
      app
      :color="'topMenu'"
      :parentdrawerLeft="drawerLeft"
      class="white--text"
      :style="{ 'border-bottom': `2px solid ${appSettings.logoTextColor}` }"
    >
      <v-app-bar-nav-icon
        dark
        @click.stop="drawerLeft = !drawerLeft"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        v-if="!$vuetify.breakpoint.xsOnly"
        class="appLogo"
        title="Hume + Hovell Trip Planner"
      >
        <img src="/assets/HH_nav-logo.png" />
        {{ appTitle }}
      </v-toolbar-title>

      <v-toolbar-title
        v-if="$vuetify.breakpoint.xsOnly"
        class="appLogoMobile d-flex  flex-column justify-center align-center"
        title="Hume + Hovell Trip Planner"
      >
        <img src="/assets/HH_nav-logo.png" />

        <div>{{ appTitle }}</div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <div v-show="!$vuetify.breakpoint.smAndDown">
        <v-btn
          v-if="false"
          elevation="1"
          tile
          color="primary"
          class="mr-1"
          @click="loadTripReportDialog()"
        >
          <v-icon left>
            mdi-note-text-outline
          </v-icon>
          Trip report
        </v-btn>
        <v-spacer></v-spacer>

        <v-btn
          elevation="1"
          tile
          color="primary"
          class="mr-1"
          @click="resetEverything()"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          Reset
        </v-btn>

        <v-btn
          v-if="false"
          elevation="1"
          tile
          color="primary"
          class="mr-1"
          @click="
            custStartEnd_markersSetDraggable({ start: false, end: false })
          "
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          Disable
        </v-btn>

        <v-btn
          v-if="false"
          elevation="1"
          tile
          color="primary"
          class="mr-1"
          @click="custStartEnd_markersSetDraggable({ start: true, end: true })"
        >
          <v-icon left>
            mdi-reload
          </v-icon>
          Enable
        </v-btn>
      </div>
      <v-menu bottom left style="z-index:100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark color="" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card tile class="mainMenu">
          <!-- Close button at the top right of the menu -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="menu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <v-btn
              elevation="1"
              tile
              block
              color="primary"
              class="mr-1"
              @click="resetEverything()"
            >
              <v-icon left>
                mdi-reload
              </v-icon>
              Reset
            </v-btn>

            <v-divider></v-divider>

            <v-btn
              elevation="1"
              block
              tile
              color="primary"
              class="mr-1"
              @click="loadTripReportDialog()"
            >
              <v-icon left>
                mdi-note-text-outline
              </v-icon>
              Trip report
            </v-btn>

            <v-divider></v-divider>
            <v-btn
              v-if="elevationData && elevationData.campsiteDistElevationArray"
              elevation="1"
              block
              tile
              color="primary"
              class="mr-1"
              @click="loadElevationGraphDialog()"
            >
              <v-icon left>
                mdi-chart-timeline-variant
              </v-icon>
              Elevation Plot
            </v-btn>

            <v-divider v-if="posts.length"></v-divider>

            <v-btn
              v-if="posts.length"
              elevation="1"
              block
              tile
              color="primary"
              class="mr-1"
              @click="loadAnnouncementDialog()"
            >
              <v-icon left>
                mdi-alert
              </v-icon>
              Announcements
            </v-btn>

            <v-divider v-if="hasHelp"></v-divider>
            <v-btn
              v-if="hasHelp"
              elevation="1"
              block
              tile
              color="primary"
              class="mr-1"
              @click="loadHelpDialog()"
            >
              <v-icon left>
                mdi-help
              </v-icon>
              Help
            </v-btn>

            <v-divider></v-divider>
            <v-btn
              elevation="1"
              block
              tile
              color="primary"
              class="mr-1"
              href="https://www.humeandhovelltrack.com.au/"
            >
              <v-icon left>
                mdi-link
              </v-icon>
              Main Page
            </v-btn>

            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid class="mapFrame" style="margin: 0;padding: 0;">
        <v-layout fill-height>
          <Map :drawer="drawerLeft" />
        </v-layout>
      </v-container>
    </v-main>
    <helpDialog slug="help" title="Help"></helpDialog>
    <announcementsDialog></announcementsDialog>
    <mediaDialog></mediaDialog>
    <v-footer dense app :color="'accent'" class="white--text" v-if="false">
      footer
    </v-footer>

    <layerSelector></layerSelector>
    <TrailOptions />
    <mapLegend></mapLegend>
  </div>
</template>

<script>
import { eventBus } from "./main";

import { mapState } from "vuex";

import Map from "./components/map/Map.vue";

import TrailOptions from "./components/controls/trailOptions.vue";
//import CampSiteSideList from "./components/campsiteList/CampSiteSideList.vue";
import sidebar from "./components/sidebar/sidebar.vue";

import mediaDialog from "./components/media/mediaDialog.vue";
import helpDialog from "./components/helpDialog/helpDialog.vue";
import announcementsDialog from "./components/announcementsDialog/announcementsDialog.vue";
import mapLegend from "./components/legend/mapLegend.vue";

import layerSelector from "./components/layerSelector/layerSelector.vue";

export default {
  components: {
    mapLegend,
    Map,
    TrailOptions,
    sidebar,
    mediaDialog,
    helpDialog,
    announcementsDialog,
    layerSelector
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,

      elevationData: state => state.trail.elevationData,

      isDevEnviroment: state => state.navigation.isDevEnviroment,
      pages: state => state.mapsetupdate.pages,
      posts: state => state.mapsetupdate.posts
    }),
    hasHelp() {
      let helpPost = this.pages.filter(row => (row.slug = "help"))[0];
      return helpPost ? true : false;
    }
  },
  data: () => ({
    appTitle: "Trip Planner",
    drawerLeft: false
  }),
  methods: {
    loadElevationGraphDialog() {
      eventBus.$emit("loadElevationGraphDialog");
    },

    loadAnnouncementDialog() {
      this.openIntro = false;
      eventBus.$emit("loadAnnouncementDialog");
    },
    loadHelpDialog() {
      this.openIntro = false;
      eventBus.$emit("load_helpDialog");
    },
    loadTripReportDialog() {
      this.openIntro = false;
      eventBus.$emit("loadTripReportDialog");
    },

    loadWelcome() {
      this.openIntro = false;
      eventBus.$emit("loadWelcomeDialog");
    },
    loadContact() {
      this.openIntro = false;
      eventBus.$emit("loadContactUsDialog");
    },
    loadAbout() {
      this.openIntro = false;
      eventBus.$emit("loadAboutDialog");
    },

    resetEverything() {
      eventBus.$emit("nav_map_reset");
      eventBus.$emit("nav_trail_options");
    }
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawerLeft = false;
      console.log("close");
    } else {
      this.drawerLeft = true;
      console.log("opne");
    }
  },
  created() {
    eventBus.$on("nav_layout_openMenu", () => {
      this.drawerLeft = true;
    });

    eventBus.$on("nav_layout_closeMenu", () => {
      this.drawerLeft = false;
    });
  },
  watch: {}
};
</script>

<style>
/* gets rid of the banner at the top of the page */

.appLogo {
  height: 30px;
  padding-left: 10px !important;
}
.appLogo img {
  height: 20px;
  vertical-align: inherit;
  margin-bottom: -2px;
}
.appLogoMobile {
  height: 44px;
  padding-left: 5px !important;
}
.appLogoMobile div {
  font-size: 16px;
  padding-right: 30px;
}
.appLogoMobile img {
  height: 18px;
  vertical-align: inherit;
  margin-bottom: -2px;
}

body {
  top: 0 !important;
}
/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}

.mapFrame,
.mapWrapper,
#mapContainer {
  height: calc(100vh - 48px);

  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1263px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 110px);
    max-height: calc(var(--doc-height) - 110px);

    height: calc(100vh - 48px);
    max-height: calc(var(--doc-height) - 48px);
  }
}
@media only screen and (max-width: 959px) {
  .mapFrame,
  .mapWrapper,
  #mapContainer {
    height: calc(100vh - 90px);
    max-height: calc(var(--doc-height) - 90px);

    height: calc(100vh - 90px + 8px);
    max-height: calc(var(--doc-height) - 90px + 8px);

    height: calc(100vh - 48px);
    max-height: calc(var(--doc-height) - 48px);
  }

  footer {
    font-size: 0.7em;
    height: 34px;
  }
}
.v-card__title {
  word-break: initial;
}

@media only screen and (max-width: 960px) {
  .v-footer .mr-8 {
    margin-right: 8px !important;
  }
}

.fakeEmptyMenuClass {
  height: auto;
  min-height: 10px;
  min-width: 10px;
}

.isfullScreenMode .mapboxgl-ctrl mapbox-search-box {
  display: none !important;
}

.mapboxgl-ctrl mapbox-search-box input::placeholder {
  color: rgba(255, 255, 255, 0.95);
}
@media only screen and (max-width: 1263px) {
  .mapboxgl-ctrl-top-right > .mapboxgl-ctrl {
    max-width: 200px;
  }
  mapbox-search-listbox > div > div {
    margin-left: -102px;
  }
}

@media only screen and (max-width: 960px) {
  .mapboxgl-ctrl-top-right > .mapboxgl-ctrl {
    max-width: 150px;
  }

  mapbox-search-listbox > div > div {
    margin-left: -102px;
  }
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-top-left
  .mapboxgl-ctrl-group
  button,
body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl-group
  button {
  width: 36px;
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-top-right
  .mapboxgl-ctrl-group
  button {
  height: 28px;
}

body div.v-application.my-app .mapboxgl-ctrl-group.fakeEmptyMenuClass {
  border-radius: 4px;
  xbackground-color: transparent;
}

body
  div.v-application.my-app
  .mapboxgl-ctrl-group.fakeEmptyMenuClass
  button.mapElementButton {
  border-radius: 4px;
}

.mainMenu .v-btn__content {
  justify-content: left;
}
</style>
