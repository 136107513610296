import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify, {
  iconfont: "md"
});
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#592625",

        xprimary: "#cccccc",

        topMenu: "#495a58",
        controlbox: "#c0cfcd",

        accent: "#495a58",
        secondary: "#217c75",
        warning: "#DD8637",
        success: "#f2f2f2",
        darkgrey: "#4f4f4f"
        //primary: "#018e42"
      }
    }
  }
});
