<template>
  <v-dialog
    v-model="dialog"
    class="wpdataHelp"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="630px"
  >
    <v-card>
      <v-toolbar dark dense flat color="warning">
        <v-spacer></v-spacer>
        <v-toolbar-title>Announcements</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="scrollable-content pb-10">
        <div class="mx-10">
          <center>
            <a href="https://www.humeandhovelltrack.com.au/" target="_blank"
              >** See humeandhovelltrack.com.au/ for the most up-to-date
              information.</a
            >
          </center>
        </div>
        <div
          class="postBlock"
          v-for="(item, index) in postsUpdate"
          :key="`posts_${index}`"
          style=""
        >
          <v-card-title v-html="item.title"></v-card-title>
          <v-card-subtitle
            v-if="item.formattedDate"
            v-html="item.formattedDate"
          ></v-card-subtitle>
          <v-card-text class="bodyText">
            <div v-html="item.content"></div>
          </v-card-text>

          <hr vx-if="postsUpdate.length != index+1" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { eventBus } from "../../main";

import { mapState } from "vuex";
import moment from "moment";

export default {
  name: "announcementsDialog",

  data() {
    return {
      dialog: false
    };
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      posts: state => state.mapsetupdate.posts
    }),
    hasPosts() {
      return this.posts.length;
    },
    postsUpdate() {
      return this.posts.map(item => {
        try {
          const gmdDate = item.date_gmt + "Z";
          item.formattedDate = moment
            .utc(gmdDate)
            .local()
            .format("dddd, D MMMM YYYY");
        } catch (error) {
          //do nothing;
          console.log({ error });
        }

        return item;
      });
    }
  },

  destroyed() {
    eventBus.$off("loadAnnouncementDialog");
  },
  mounted() {
    eventBus.$on("loadAnnouncementDialog", () => {
      this.dialog = true;
    });

    setTimeout(() => {
      if (this?.posts?.length) {
        this.dialog = true;
      }
    }, 1500);
  }
};
</script>

<style>
.postBlock hr {
  border-bottom: 2px solid grey;
  margin-left: 14px;
  margin-right: 14px;
}

.postBlock:last-child hr {
  border-bottom: none;
}
</style>
