<template>
  <div id="mapLayerSelector" :class="!open ? 'closedMenu' : 'openMenu'">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group " v-show="!open">
      <button
        class="mapboxgl-ctrl-fullscreen mapboxLayerButton"
        @click="open = !open"
      ></button>
    </div>

    <div id="mapLayerSelectorItems" v-show="open">
      <v-card class="mx-auto" width="200px">
        <v-toolbar flat dense color="topMenu accent-4" dark>
          <v-toolbar-title>Layer Selector</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="open = !open">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list id="layerItemsList" dense>
          <v-list-item v-for="item in layerCheckboxValues" :key="item.id">
            <template
              v-if="item.acf && item.Separator && item.Separator === true"
            >
              <v-divider></v-divider>
            </template>
            <template v-else>
              <v-list-item-content dense class="layerItemsListItem">
                <v-checkbox
                  @change="updateLayer()"
                  class="pt-0 mt-1"
                  v-model="item.value"
                  :label="item.name"
                  color="secondary"
                  hide-details
                ></v-checkbox>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "layerSelector",

  components: {},

  computed: {
    ...mapState({
      legendItems: state => state.mapsetupdate.legendItems
    })
  },

  data: () => ({
    open: false,

    layerCheckboxValues: [
      {
        id: "hh_access-roads",
        name: "Access Roads",
        value: true,
        default: true
      },
      {
        id: "hh-trackChainage",
        otherLayers: ["hh-trackChainage", "hh-trackChainage-Labels"],
        name: "Track Chainage",
        value: true,
        default: true
      },
      {
        id: "hh-poi-layer",
        name: "Points of Interest",
        value: true,
        default: true
      },
      {
        id: "hh-short-walks-layer-notpoi",
        name: "Short Walks",
        value: true,
        default: true
      },
      {
        id: "3dterrain",
        name: "3D Terrain",
        terrain: true,
        value: true,
        default: true
      }
    ]
  }),
  methods: {
    setLayer(layerName, value) {
      this.layerCheckboxValues.forEach(row => {
        if (row.name === layerName) {
          row.value = value === true ? true : false;
        } else if (row.id === layerName) {
          row.value = value === true ? true : false;
        }
      });
      this.updateLayer();
    },
    resetLayer() {
      this.layerCheckboxValues.forEach(row => {
        row.value = row.default ? true : false;
      });

      this.updateLayer();
    },

    updateLayer() {
      let showLayers = [];
      let hideLayers = [];

      this.layerCheckboxValues.forEach(layer => {
        if (layer.value === true) {
          showLayers.push(layer.id);
          if (layer.otherLayers) {
            layer.otherLayers.forEach(row => {
              showLayers.push(row);
            });
          }
        } else {
          hideLayers.push(layer.id);
          if (layer.otherLayers) {
            layer.otherLayers.forEach(row => {
              hideLayers.push(row);
            });
          }
        }
      });
      showLayers = [...new Set(showLayers)];
      hideLayers = [...new Set(hideLayers)];
      let layers = window.thismap.getStyle().layers;
      for (const layer of layers) {
        let { id: layerId } = layer;
        if (layerId && showLayers.includes(layerId)) {
          try {
            window.thismap.setLayoutProperty(layerId, "visibility", "visible");
          } catch (error) {
            //do nothing;
          }
        } else if (layerId && hideLayers.includes(layerId)) {
          try {
            window.thismap.setLayoutProperty(layerId, "visibility", "none");
          } catch (error) {
            //do nothing;
          }
        }
      }

      try {
        if (window?.thismap?.getSource("mapbox-dem")) {
          //("Source is available");

          if (showLayers.includes("3dterrain")) {
            window.thismap.setTerrain({
              source: "mapbox-dem",
              exaggeration: 1.5
            });
          }
          if (hideLayers.includes("3dterrain")) {
            window.thismap.setTerrain({
              source: "mapbox-dem",
              exaggeration: 0
            });
          }
        } else {
          //("Source is not available");
        }
      } catch (error) {
        //do nothing;
        console.error({ error });
      }
    }
  },

  destroyed() {
    eventBus.$off("layerSelector_close");
    eventBus.$off("layerSelector_reset");
    eventBus.$off("layerSelector_setlayer");
  },
  mounted() {
    eventBus.$on("layerSelector_close", () => {
      this.open = false;
    });
    eventBus.$on("layerSelector_reset", () => {
      this.resetLayer();
    });
    eventBus.$on("layerSelector_setlayer", (layerName, value) => {
      this.setLayer(layerName, value);
    });
  },

  watch: {
    open: function(newValue) {
      if (newValue) {
        eventBus.$emit("mapLegend_close");
      }
    }
  }
};
</script>

<style>
#mapLayerSelector .v-input .v-label {
  color: rgba(0, 0, 0, 0.87);
}
#mapLayerSelector {
  position: fixed;
  top: 58px;
  right: 10px;
  height: 28px;
  width: 36px;
  overflow: visible;
}

#mapLayerSelector button.mapboxgl-ctrl-fullscreen {
  height: 28px;
  width: 36px;
}

#mapLayerSelectorItems {
  position: absolute;
  top: 0;
  right: 0;
}

#mapLayerSelector .v-list-item {
  display: inline-block;
  width: 100%;
  min-height: 10px;
}
#mapLayerSelector .v-list-item .v-divider {
  padding-top: 2px;
}
#mapLayerSelector .v-list-item div {
  display: inline-block;
  padding: 0;
}
</style>
