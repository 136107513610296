<template>
  <div id="mapLegend" :class="!open ? 'closedMenu' : 'openMenu'">
    <div class="mapboxgl-ctrl mapboxgl-ctrl-group " v-show="!open">
      <button class="mapboxgl-ctrl-fullscreen " @click="open = !open">
        <v-icon style="color: black!important;width:35px"
          >mdi-map-legend</v-icon
        >
      </button>
    </div>

    <div id="mapLegendItems" v-show="open">
      <v-card
        class="mx-auto"
        :width="$vuetify.breakpoint.xsOnly ? '200px' : '300px'"
      >
        <v-toolbar flat dense color="topMenu accent-4" dark>
          <v-toolbar-title>Map Legend</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="open = !open">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-list id="legendItemsList">
          <v-list-item v-for="item in legendItems" :key="item.postId">
            <template
              v-if="
                item.acf &&
                  item.acf.Separator &&
                  item.acf.Separator.value &&
                  item.acf.Separator.value === true
              "
            >
              <v-divider></v-divider>
            </template>
            <template v-else>
              <v-list-item-content class="legendImageWrapper">
                <div
                  v-if="
                    item.featured_image_obj &&
                      item.featured_image_obj.legend_item
                  "
                >
                  <img
                    :src="item.featured_image_obj.legend_item"
                    class="legendImageItem"
                  />
                </div>
              </v-list-item-content>

              <v-list-item-content class="legendTitleWrapper">
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { eventBus } from "../../main";

export default {
  name: "mapLegend",

  components: {},

  computed: {
    ...mapState({
      legendItems: state => state.mapsetupdate.legendItems
    })
  },

  data: () => ({
    open: false
  }),
  methods: {},

  destroyed() {
    eventBus.$off("mapLegend_close");
  },
  mounted() {
    eventBus.$on("mapLegend_close", () => {
      this.open = false;
    });
  },
  watch: {
    open: function(newValue) {
      if (newValue) {
        eventBus.$emit("layerSelector_close");
      }
    }
  }
};
</script>

<style lang="scss">
//to move attribution for mobiel only

#trailControlWrapper,
#mapLayerSelector,
#mapLegend {
  z-index: 2;
}

#trailControlWrapper.openMenu,
#mapLayerSelector.openMenu,
#mapLegend.openMenu {
  z-index: 5;
}

#mapLegend {
  position: fixed;
  bottom: 33px;
  right: 10px;
  height: 28px;
  width: 36px;
  xbackground-color: red;
  overflow: visible;
}

#mapLegend button.mapboxgl-ctrl-fullscreen {
  height: 28px;
  width: 36px;
}

#mapLegendItems {
  position: absolute;
  bottom: 0;
  right: 0;
}

#legendItemsList {
  max-height: 800px;

  max-height: calc(var(--doc-height) - 210px);

  overflow-y: auto;
}

.legendImageItem {
  height: 25px;
  max-width: 70px;
  flex: none;
}

.legendImageWrapper {
  flex-shrink: 0;
  width: 50px;
  text-align: center;
  margin-right: 10px;
  overflow: hidden;
}

.legendImageWrapper > div {
  width: 50px;
  padding: 0;
}

.legendTitleWrapper .v-list-item__title {
  max-width: 206px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.legendTitleWrapper {
  flex-grow: 3;
  padding: 0;
  margin-bottom: -2px;
}

#mapLegend .v-list-item {
  display: inline-block;
  width: 100%;
  min-height: 10px;
}
#mapLegend .v-list-item .v-divider {
  padding-top: 2px;
}
#mapLegend .v-list-item div {
  display: inline-block;
  padding: 0;
}

.isMobile {
  #mapLegend .v-list-item {
    padding: 0;
  }

  .legendImageItem {
    height: 20px;
    max-width: 60px;
    flex: none;
  }
}

@media only screen and (max-width: 639px) {
  #mapLegend {
    bottom: 10px;
  }
  .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin-right: 55px;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-attrib.mapboxgl-compact.mapboxgl-compact-show {
    margin-right: 0;
    margin-bottom: 40px;
    z-index: 5000000000000000;
  }
}
</style>
