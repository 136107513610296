var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.open ? 'closedMenu' : 'openMenu',attrs:{"id":"mapLegend"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.open),expression:"!open"}],staticClass:"mapboxgl-ctrl mapboxgl-ctrl-group "},[_c('button',{staticClass:"mapboxgl-ctrl-fullscreen ",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('v-icon',{staticStyle:{"color":"black!important","width":"35px"}},[_vm._v("mdi-map-legend")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],attrs:{"id":"mapLegendItems"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? '200px' : '300px'}},[_c('v-toolbar',{attrs:{"flat":"","dense":"","color":"topMenu accent-4","dark":""}},[_c('v-toolbar-title',[_vm._v("Map Legend")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list',{attrs:{"id":"legendItemsList"}},_vm._l((_vm.legendItems),function(item){return _c('v-list-item',{key:item.postId},[(
              item.acf &&
                item.acf.Separator &&
                item.acf.Separator.value &&
                item.acf.Separator.value === true
            )?[_c('v-divider')]:[_c('v-list-item-content',{staticClass:"legendImageWrapper"},[(
                  item.featured_image_obj &&
                    item.featured_image_obj.legend_item
                )?_c('div',[_c('img',{staticClass:"legendImageItem",attrs:{"src":item.featured_image_obj.legend_item}})]):_vm._e()]),_c('v-list-item-content',{staticClass:"legendTitleWrapper"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]],2)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }