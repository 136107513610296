let datasets = [
  {
    name: "normal",
    trackId: "cm43hx3mw4meb1nphod110owkxxx"
    //campsitesId: "clvkf53dusmr81ms27nbj6a28"
  }
];

let mapboxAccessToken =
  "pk.eyJ1IjoidGltMTJwIiwiYSI6ImNtNDNqdjJ2eTBjdWkyaXB2dDZ1ZXFnMXYifQ.uWif0RA0pMYd5IztYwg-6w";
let mapboxUserId = "tim12p";

const HH_backupTrack = require("./../wpdata/cachedData/HH_backupTrack.json");
console.log({ HH_backupTrack });

//const HH_SectionSetsFallBack = require("./data/default/HH_SectionSets.json");
//const HH_CampsiteDataFallBack = require("./data/default/myWP_HH_TRACK_CAMPSITES_NORMAL.geojson.json");

//console.log({ HH_CampsiteDataFallBack});

const requestData = function(datasetId, apiUrl) {
  const promise = new Promise(resolve => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        resolve({ datasetId, data });
      })
      .catch(error => {
        console.error("requestData error", { datasetId, error });
        resolve(false);
      });
  });

  return promise;
};

const _getData = async datasetId => {
  try {
    const apiUrl = `https://api.mapbox.com/datasets/v1/${mapboxUserId}/${datasetId}/features?access_token=${mapboxAccessToken}`;
    //console.log({ apiUrl });
    let result = await requestData(datasetId, apiUrl);
    return result;
  } catch (error) {
    return null;
  }
};

const _loadMbData = async wpdata => {
  let mapboxsetup = wpdata?.settings?.mapboxsetup;

  let HH_CampsiteDataGeoJson, HH_SectionSets;

  if (wpdata?.geoDataObject?.point?.length) {
    HH_CampsiteDataGeoJson = {
      type: "FeatureCollection",
      name: "HH_TRACK_CAMPSITES_WGS84_FromCMS",
      features: wpdata?.geoDataObject?.point ? wpdata.geoDataObject.point : []
    };
  }

  if (wpdata?.geoLinkedDataObject?.section?.length) {
    HH_SectionSets = wpdata.geoLinkedDataObject.section;
  }

  if (!HH_CampsiteDataGeoJson?.features?.length) {
    HH_CampsiteDataGeoJson = {};
  }
  if (!HH_SectionSets?.length) {
    HH_SectionSets = [];
  }
  if (mapboxsetup?.MapToken) {
    if (mapboxsetup?.MapToken) {
      mapboxAccessToken = mapboxsetup?.MapToken;
    }
    if (mapboxsetup?.MapUser) {
      mapboxUserId = mapboxsetup?.MapUser;
    }

    if (mapboxsetup?.MapboxTrailDataset) {
      datasets = [
        {
          name: "normal",
          trackId: mapboxsetup?.MapboxTrailDataset
          //campsitesId: "clvkf53dusmr81ms27nbj6a28"
        }
      ];

      //console.log("datasets set");
    }
  } else {
    console.error("error loading wp settings for map trail/token/style");
  }

  let mapboxTrailDataObject = {};
  let isOffLineData = false;

  for (const dataset of datasets) {
    let { name, trackId, campsitesId } = dataset;

    let trackData = null;

    if (_getData) {
      //do nothing;
    }

    try {
      trackData = await _getData(trackId);
    } catch (error) {
      //      trackData = {data:{...HH_backupTrack}, datasetId:trackId};
    }

    if (
      !trackData ||
      !trackData.data ||
      !trackData.data.features ||
      !trackData.data.features.length
    ) {
      trackData = { data: { ...HH_backupTrack }, datasetId: trackId };
      isOffLineData = true;

      console.error(
        "There has been an issue loading mapbox trail, using fallback track data."
      );
    }

    //let campsitesData = await _getData(campsitesId);

    let campsitesData = {
      data: HH_CampsiteDataGeoJson
      //datasetId: "clvkf53dusmr81ms27nbj6a28xx"
    };

    mapboxTrailDataObject[name] = {
      name,
      trackId,
      campsitesId,
      trackData,
      campsitesData,
      sectionSet: name == "normal" ? HH_SectionSets : null
    };
  }

  return { mapboxTrailDataObject, isOffLineData };
};

module.exports.loadMbData = _loadMbData;
