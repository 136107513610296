<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="background:black">
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Campsite: {{ markertitle }} -

            <template v-if="title != 'gallery'">
              <font v-if="this.type.single === this.title">
                {{ this.title }}
              </font>
              <font v-else>
                {{ type.single }}:
                {{ title }}
              </font>
            </template>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div
          class="fullHeight"
          v-if="gallery && gallery.length && type.prop === 'gallery'"
        >
          <v-carousel
            class="fullHeightMax"
            v-if="gallery && gallery.length"
            hide-delimiters
            :show-arrows="gallery.length > 1"
            height="auto"
            style=""
          >
            <v-carousel-item
              class="fullHeight"
              v-for="(item, i) in gallery"
              :key="i"
            >
              <div class="fullHeight">
                <div class="fullHeight">
                  <center
                    style="position: relative;top: 50%;transform: translateY(-50%);"
                  >
                    <div>
                      <img
                        :src="item.gallery_wide"
                        key=""
                        class="fullHeightMax"
                        style="
          
              display: block;
              max-width: 100%;
              width: auto;
              height: auto;"
                      />

                      <img
                        v-if="gallery[i + 1]"
                        :src="gallery[i + 1].gallery_wide"
                        style="
                    opacity: 0.01;
                    display: block;
                    width: 1px;
                    height: 1px;"
                      />

                      <div class="imageCaption" v-if="item.caption">
                        {{ item.caption }}
                      </div>
                    </div>
                  </center>
                </div>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>

        <div class="fullHeight" v-if="url && type.prop === 'streetviews'">
          <iframe
            :src="url"
            width="100%"
            height="100%"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>

        <div class="fullHeight" v-if="url && type.prop === 'videos'">
          <youtube
            player-width="100%"
            :video-id="getUrl(url)"
            :player-vars="{ autoplay: 1 }"
          ></youtube>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { eventBus } from "../../main";
import { getIdFromURL } from "vue-youtube-embed";

export default {
  name: "mediaDialog",

  methods: {
    getUrl(url) {
      let videoId = getIdFromURL(url);
      return videoId;
    }
  },

  created() {
    eventBus.$on("loadMediaDialog", setup => {
      this.markertitle = setup.markertitle;
      this.type = setup.type;
      this.title = setup.title;
      if (setup.url) this.url = setup.url;

      if (setup.gallery) this.gallery = setup.gallery;

      this.dialog = true;
    });
  },

  data() {
    return {
      dialog: false,
      type: {},
      markertitle: "",
      title: "",
      url: null,
      gallery: null
    };
  },

  watch: {
    dialog: function(newValue) {
      if (!newValue) {
        this.type = {};
        this.title = "";
        this.url = null;
        this.gallery = null;
      }
    }
  }
};
</script>
<style>
.fullHeight {
  overflow-y: hidden !important;
  background-color: rgb(51, 51, 51);
  height: calc(100vh - 64px);
  max-height: 100%;
}
.fullHeight > div > iframe,
.fullHeight > div {
  height: calc(100vh - 64px);
  max-height: 100%;
}

.isMobile .fullHeight,
.isMobile .fullHeight iframe,
.isMobile .fullHeight > div iframe,
.isMobile .fullHeight > div {
  height: calc(100vh - 128px);
  max-height: 100%;
}
</style>
