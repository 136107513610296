//import { _ } from "core-js";

const getters = {};
const actions = {
  filters_update_icons({ commit }, value) {
    commit("UPDATE_ICONS", value);
  }
};

const mutations = {
  UPDATE_ICONS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.iconlist));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.iconlist = JSON.parse(JSON.stringify(value));
    }
  }
};

const state = {
  iconlist: {}
};

export default {
  state,
  getters,
  actions,
  mutations
};

/*
//import { _ } from "core-js";

const getters = {};
const actions = {
  filters_enableLayer({ state, commit }, layer) {
    let layers = JSON.parse(JSON.stringify(state.layers));

    if (layer && !layers.includes(layer)) {
      layers.push(layer);
      commit("UPDATE_LAYERS", layers);
    }
  },

  filters_controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
  },

  filters_updateData({ commit }, wpData) {
    const categoryLookup = wpData.categoryLookup;

    const categoryArray = _.values(categoryLookup);

    let bools = categoryArray.reduce(function(map, obj) {
      map[obj.key] = true;
      return map;
    }, {});

    commit("SET_selected", { bools: bools });

    var layers = _.values(categoryLookup)
      .filter(layer => layer.hidden_by_default != true)
      .map(layer => layer.key);


    commit("SET_defaultLayers", layers);
    //commit("SET_layers", _.keys(categoryLookup));
    commit("SET_layers", layers);

    commit("SET_setup", { bools: [...categoryArray] });
  },

  filters_resetLayers({ state, commit }) {
    commit("UPDATE_LAYERS", state.defaultLayers);
  }
};

const mutations = {
  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  },

  SET_selected(state, newValue) {
    state.selected = newValue;
  },
  SET_defaultLayers(state, newValue) {
    state.defaultLayers = newValue;
  },
  SET_layers(state, newValue) {
    state.layers = newValue;
  },
  SET_setup(state, newValue) {
    state.setup = newValue;
  }
};

import { _ } from "core-js";

const state = {
  selectable: {},
  selected: {
    bools: {}
  },

  defaultLayers: [], //["History", "Prospective Families", "Wayfinding"],
  layers: [], //["History", "Prospective Families", "Wayfinding"],
  setup: {
    bools: []
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
*/
