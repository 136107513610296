var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{class:{
    isDevMode: _vm.isDevMode,
    mobileShowFilters: _vm.mobileShowFilters,
    mobileShowSuburbList: _vm.mobileShowSuburbList,
    mobileHideFilters: !_vm.mobileShowFilters,
    mobileHideSuburbList: !_vm.mobileShowSuburbList,

    //these are used so that small computers are classed as tablets;
    isComputer: !_vm.$vuetify.breakpoint.mdAndDown,
    isSmallComputer: !_vm.$vuetify.breakpoint.smAndDown,
    isSmallPcOrTabletOrMobile: _vm.$vuetify.breakpoint.mdAndDown,
    isTabletOrMobile: _vm.$vuetify.breakpoint.smAndDown,

    isTablet: _vm.$vuetify.breakpoint.smOnly,
    isNotTablet: !_vm.$vuetify.breakpoint.smOnly,

    /*
        this is commented out to disable mediumComputerMode;
          isComputer: !$vuetify.breakpoint.smAndDown,
          isMediumComputer: $vuetify.breakpoint.mdOnly,
          isTabletOrMobile: $vuetify.breakpoint.smAndDown,
    */

    isfullScreenMode: _vm.fullScreenMode,

    isMobile: _vm.$vuetify.breakpoint.xsOnly,
    'my-app': true,
    isApple: _vm.isIOS
  }},[(_vm.ready && _vm.mapboxSetup.MapToken && _vm.mapboxSetup.MapStyle)?_c('Layout'):_vm._e(),_c('loadingScreen')],1)}
var staticRenderFns = []

export { render, staticRenderFns }