<template>
  <div>
    <div v-if="url && type.prop === 'links'">
      <v-btn
        class="custom-media-btn"
        block
        color="primary"
        tile
        small
        @click="clickButton()"
      >
        <v-icon left>{{ type.icon }}</v-icon>
        <div class="btn-multiline-ellipsis">
          {{ titleCompute }}
        </div>
      </v-btn>
    </div>

    <div
      v-if="
        (url && (type.prop === 'streetviews' || type.prop === 'videos')) ||
          type.prop === 'gallery'
      "
    >
      <v-btn
        class="custom-media-btn"
        block
        color="primary"
        tile
        small
        @click="clickButton()"
      >
        <v-icon left>{{ type.icon }}</v-icon>
        <div class="btn-multiline-ellipsis">
          {{ titleCompute }}
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";

export default {
  name: "mediaButton",

  components: {},

  data: () => ({}),
  props: {
    width: Object,
    type: Object,
    title: String,
    markertitle: String,
    url: String,
    gallery: Array
  },
  methods: {
    openLink(url) {
      window.open(url, "_blank");
    },
    clickButton() {
      if (
        this.type.prop === "links" ||
        (this.type.prop === "videos" && !this.url?.includes("youtube"))
      ) {
        this.openLink(this.url);
      } else {
        //do nothing;
        eventBus.$emit("loadMediaDialog", {
          type: this.type,
          title: this.titleCompute,
          markertitle: this.markertitle,
          url: this.url,
          gallery: this.gallery
        });
      }
    }
  },
  computed: {
    titleCompute() {
      if (this.title) {
        return this.title;
      } else {
        return this.type.single;
      }
    }
  }
};
</script>

<style>
.custom-media-btn,
.custom-media-btn span {
  max-width: 100%;
}

.btn-multiline-ellipsis {
  max-width: 100%;
  max-height: 3em; /* Adjust based on your line-height; 3em assumes 1.5em per line */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  line-height: 1;
}
</style>
