//import _, { initial } from "lodash";
import { mapState } from "vuex";

//import { eventBus } from "./../../../../main";

var width = 1;
var bytesPerPixel = 4;
var blankImage = new Uint8Array(width * width * bytesPerPixel);
for (var x = 0; x < width; x++) {
  for (var y = 0; y < width; y++) {
    var offset = (y * width + x) * bytesPerPixel;
    blankImage[offset + 0] = 1; // red
    blankImage[offset + 1] = 1; // green
    blankImage[offset + 2] = 1; // blue
    blankImage[offset + 3] = 0; // alpha
  }
}

const fallBack = [
  {
    id: 10025,
    name: "x_CampMarker_closed",
    src:
      "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_closed-50x50.png",
    height: 100,
    width: 100
  },
  {
    id: 10026,
    name: "x_CampMarker_finish",
    src:
      "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_finish-50x50.png",
    height: 100,
    width: 100
  },
  {
    id: 10024,
    name: "x_CampMarker_normal",
    src:
      "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_normal-50x50.png",
    height: 100,
    width: 100
  },
  {
    id: 10023,
    name: "x_CampMarker_skip",
    src:
      "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_skip-50x50.png",
    height: 100,
    width: 100
  },
  {
    id: 10022,
    name: "x_CampMarker_start",
    src:
      "https://tripplannercms.humeandhovelltrack.com.au/wp-content/uploads/2025/03/x_CampMarker_start-50x50.png",
    height: 100,
    width: 100
  }
];

export default {
  data: () => ({}),

  computed: {
    ...mapState({
      iconcategory: state => state.mapsetupdate.iconcategory
    }),
    imageList() {
      let imageList = this.iconcategory.map(row => {
        let { id, name, catimage } = row;
        let { very_small_marker } = catimage;

        return {
          id,
          name,
          src: very_small_marker,
          height: 100,
          width: 100
        };
      });

      return imageList;
    }
  },

  methods: {
    loadImage() {},
    async initializeMarkerIcons() {
      var imageList = [...this.imageList];
      fallBack.forEach(row => {
        try {
          if (
            imageList.filter(marker => marker.name === row.name).length == 0
          ) {
            imageList.push(row);
          }
        } catch (error) {
          //do nothing;
        }
      });

      let imageListObj = {};

      imageList.forEach(row => {
        imageListObj[row.name] = row;
      });

      this.$store.dispatch("filters_update_icons", imageListObj);

      imageList.forEach(markerImage => {
        let { id } = markerImage;

        this.map.addImage(id, {
          width: 1,
          height: 1,
          data: blankImage
        });
      });

      imageList.forEach(markerImage => {
        let { id, src, name } = markerImage;

        this.map.loadImage(`${src}`, async (error, image) => {
          if (error) {
            console.error("error");
          } else {
            this.map.removeImage(id);

            this.map.addImage(id, image, { pixelRatio: 2 });
            try {
              this.map.addImage(name, image, { pixelRatio: 2 });
            } catch (error) {
              //do nothing;
            }
          }
        });
      });
    }
  },
  destroyed() {},
  mounted() {},
  watch: {}
};
