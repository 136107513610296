<template>
  <div max-width="450" class="mx-auto">
    <div class="shortWalksWrapper pt-2">
      <template v-for="(cat, catKey) in poiByCats">
        <v-list dense subheader :key="catKey" v-show="cat.items.length">
          <v-list-item-title
            class="text-h6 ml-5"
            style="font-size: 1rem !important;"
          >
            {{ catKey }}
          </v-list-item-title>

          <v-list-item
            dense
            v-for="item in cat.items"
            :key="item.slug"
            @click="openPopup(item)"
          >
            <v-list-item-avatar
              v-if="item.markerimage && item.markerimage.legend_item"
              size="30"
            >
              <v-img
                :alt="`${catKey} avatar`"
                :src="item.markerimage.legend_item"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="">
              <v-list-item-title
                class="three-line-ellipsis"
                v-text="item.title"
              ></v-list-item-title>

              <v-list-item-title
                class="two-line-ellipsis"
                v-if="
                  item.shortWalkOptionNames && item.shortWalkOptionNames.length
                "
                v-text="'(' + item.shortWalkOptionNames.join(', ') + ')'"
              ></v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon v-if="false">
              <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">
                mdi-message-outline
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>
    </div>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";
//import { eventBus } from "./../../main";

export default {
  name: "shortwalksList",

  data() {
    return {
      cats: ["Short Walks", "Points of Interest"],
      defaultShowCats: ["Short Walks"]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      poiItemsGeoJson: state => state.mapsetupdate.poiItemsGeoJson
    }),

    poiArray() {
      let array = this.poiItemsGeoJson.features.map(row => {
        let output = {
          ...row.properties,

          ...row
        };

        delete output.properties;

        return output;
      });

      array.sort((a, b) =>
        a.title > b.title ? 1 : b.title > a.title ? -1 : 0
      );

      return array;
    },
    allCats() {
      let allCats = [...this.cats];

      this.poiArray.forEach(row => {
        let { iconcategory } = row;
        if (!allCats.includes(iconcategory)) {
          allCats.push(iconcategory);
        }
      });

      return allCats;
    },
    poiByCats() {
      let output = {};

      this.allCats.forEach(section => {
        output[section] = {
          section,
          items: this.poiArray.filter(row => row.iconcategory == section)
        };
      });
      return output;
    }
  },
  created() {},
  methods: {
    openPopup(item) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        eventBus.$emit("nav_layout_closeMenu", false);
      }

      eventBus.$emit("map_openPoiPopupFromObj", item);
    }
  }
};
</script>

<style>
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow wrapping */
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow wrapping */
}
</style>
