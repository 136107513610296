var axios = require("axios").default;

if (!axios) {
  axios = require("axios");
}

const postPerPages = 99;
const taxPerPages = 99;

const config = require("./config");
const cleanupDataFirst = require("./cleanupDataFirst");
const cleanupDataSecond = require("./cleanupDataSecond");

//no ending slash;;

const targets = config.targets;
const wpUrl = config.wpUrl;

Array.prototype.forEachAsync = async function(fn) {
  for (let t of this) {
    await fn(t);
  }
};

Array.prototype.forEachAsyncParallel = async function(fn) {
  await Promise.all(this.map(fn));
};

const cycleThroughPosts = async function(axios, wpUrl, postType) {
  var allResults = [];

  let page = 1;
  let hasError = false;
  let wpTotalpages = 100;

  let lastResult;
  do {
    let results = await axios.get(
      `${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=${postPerPages}&page=${page}`
    );
    if (results.status !== 200) {
      hasError = true;
      break;
    }
    if (!(results?.headers && results.headers["x-wp-totalpages"])) {
      hasError = true;
      break;
    }
    if (results.data && results.data.length) {
      allResults = [...allResults, ...results.data];
    }
    wpTotalpages = results.headers["x-wp-totalpages"];
    page++;
    lastResult = results;
  } while (page <= wpTotalpages && !hasError);
  lastResult.data = allResults;
  return lastResult;
};

const getPostTypes = async function(postType, post) {
  try {
    //let results = await vm.axios.get(`${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=10`);
    let results = await cycleThroughPosts(axios, wpUrl, postType);
    if (results && results.data) post[postType.postId] = results.data;
  } catch (e) {
    //console.error("errorA", e);
  }
};
const getTaxTypes = async function(postTaxonomies, taxonomy) {
  if (postTaxonomies && postTaxonomies.length) {
    await postTaxonomies.forEachAsyncParallel(async taxType => {
      try {
        let results = await axios.get(
          `${wpUrl}/wp-json/wp/v2/${taxType}?per_page=${taxPerPages}`
        );
        if (results && results.data) taxonomy[taxType] = results.data;
      } catch (e) {
        //console.error("errorB", e);
      }
    });
  }
};

const getSettings = async function(settings) {
  //console.log("getSettings");
  try {
    let results = await axios.get(`${wpUrl}/wp-json/mv-map-setup/v1/settings/`);
    if (results && results.data) {
      settings["mapboxsetup"] = results.data;
      window["mapboxsetup"] = results.data;
    }
  } catch (e) {
    //console.error("errorB", e);
  }
};

const _getData = async function() {
  var settings = {};
  var post = {};
  var taxonomy = {};
  await targets.forEachAsyncParallel(async postType => {
    await Promise.all([
      getPostTypes(postType, post),
      getTaxTypes(postType.taxonomies, taxonomy)
    ]);
  });

  await getSettings(settings);

  var output = cleanupDataFirst.cleanup({ post, taxonomy, settings }, targets);

  output = cleanupDataSecond.cleanup(output, targets);

  return output;
};

module.exports.getData = _getData;
