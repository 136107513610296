<template>
  <v-card
    elevation="6"
    x_shaped
    v-if="ready"
    class="pb-1"
    :color="'success'"
    tile
  >
    <div
      v-if="!(properties.media_urls && properties.media_urls.length)"
      style="height:0;width:100%;overflow: visible; text-align: right;z-index:100; position:absolute"
    >
      <v-btn
        elevation="0"
        @click="clearAllPopups"
        class="ma-0"
        icon
        color="black"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-btn
      v-if="properties.media_urls && properties.media_urls.length"
      @click="loadMediaGallery"
      elevation="0"
      tile
      small
      color="white"
      class="floating-fullscreen-btn popupMediaButton"
    >
      <v-icon>mdi-arrow-expand-all</v-icon>
    </v-btn>

    <v-btn
      v-if="properties.media_urls && properties.media_urls.length"
      @click="clearAllPopups"
      elevation="0"
      tile
      small
      color="white"
      class="floating-close-btn popupMediaButton"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <PopupGallery
      v-if="properties.media_urls && properties.media_urls.length"
      :media_urls="properties.media_urls"
      :properties="properties"
    >
    </PopupGallery>
    <div class="mr-1">
      <v-card-title v-if="properties.title" class=" popupTitle pt-1 mb-1 pb-1">
        {{ properties.title }}
        <template v-if="false && (properties.id || properties.id == 0)"
          >&nbsp;({{ properties.id }})</template
        >
      </v-card-title>
      <v-card-subtitle
        class="popupSubTitle pt-2 pb-0
    "
        v-if="!isCampsite && layerId.includes('poi') && properties.iconcategory"
      >
        <img
          class="mr-1 pt-1"
          v-if="
            properties &&
              properties.markerimage &&
              properties.markerimage.very_small_marker
          "
          :src="properties.markerimage.very_small_marker"
        />

        {{ properties.iconcategory }}
      </v-card-subtitle>

      <v-card-subtitle
        v-if="properties.pointType && properties.pointType != 'normal'"
        class="text-capitalize  pb-0 darkgrey--text"
      >
        <template v-if="properties.pointType === 'closed'">
          Camp Site Closed
        </template>

        <template v-else>
          {{ properties.pointType }}
        </template>
      </v-card-subtitle>
    </div>
    <editButton class="floating-edit-btn " :id="properties.postId"></editButton>

    <template
      v-if="!isCampsite && layerId.includes('poi') && properties.content"
    >
      <v-card-text>
        <popupContentBlock
          :content="properties.content"
          :properties="properties"
          whereShow="popup"
        ></popupContentBlock>
      </v-card-text>
    </template>

    <template v-if="false && !isCampsite && layerId.includes('poi')">
      <nonCampsitePopup
        :layerId="layerId"
        :properties="properties"
        :functions="functions"
      ></nonCampsitePopup>
    </template>

    <template v-if="isCampsite">
      <v-card-text class="pb-2">
        <CampSiteDetails
          :properties="properties"
          whereShow="popup"
        ></CampSiteDetails>
      </v-card-text>

      <v-card-text class="pt-0 pb-1">
        <PopupDistToBlock
          :properties="properties"
          v-if="properties && properties.nextCampStats"
        ></PopupDistToBlock>
      </v-card-text>

      <v-card-text class="py-1" v-if="properties.pointType">
        <v-btn
          v-if="
            !properties.campsite_closed &&
              properties.pointType != 'finish' &&
              properties.pointType != 'start'
          "
          :class="{
            'checkbox-btn--checked': skipCampById.includes(properties.id),
            'checkbox-btn--unchecked': !skipCampById.includes(properties.id)
          }"
          @click="functions.handleSkipCheckboxChangePopup(properties.id)"
          class="checkbox-btn"
          elevation="0"
          x-small
        >
          <v-icon left v-if="skipCampById.includes(properties.id)"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon left v-else>mdi-checkbox-blank-outline</v-icon>
          Skip This Camp
        </v-btn>
      </v-card-text>
    </template>
    <mediaButtons
      :acfMedia="properties.acfMedia"
      :media_urls="properties.media_urls"
      :properties="properties"
    ></mediaButtons>
  </v-card>
</template>

<script>
import Vue from "vue";

import { eventBus } from "./../../../../main";

import mediaButtons from "./../../../media/mediaButtons";

import editButton from "./../../../ui/editButton.vue";

import PopupDistToBlock from "./PopupDistToBlock";
import nonCampsitePopup from "./nonCampsitePopup";
import PopupGallery from "./PopupGallery";
import CampSiteDetails from "./../../../infoblocks/CampSiteDetails";
import popupContentBlock from "./../../../infoblocks/popupContentBlock";
import "vue-json-pretty/lib/styles.css";
export default Vue.extend({
  components: {
    editButton,
    nonCampsitePopup,
    popupContentBlock,
    CampSiteDetails,
    PopupGallery,
    mediaButtons,
    PopupDistToBlock
  },
  data: () => ({
    width: 300,
    ready: false,
    propsToList: [""],
    showDetails: false,
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"]
  }),

  props: {
    //goToPointByPointName: Function,

    isCampsite: Boolean,
    layerId: String,
    clearAllPopups: Function,
    functions: Object,
    appSettings: Object,
    maplayerdata: Object,
    skipCampById: Array,
    properties: Object,
    vuetifyCopy: {
      required: true,
      type: Object
    },
    browserDetectCopy: {
      required: true,
      type: Object
    }
  },

  computed: {
    isIOS() {
      var userAgent = window.navigator.userAgent;
      let isIpad = false;
      if (
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/Macintosh/i)
      ) {
        isIpad = true;
      } else {
        // Anything else
      }

      if (
        this.$browserDetect.isSafari ||
        this.$browserDetect.isIOS ||
        this.$browserDetect.isChromeIOS ||
        isIpad
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.$vuetify = this.vuetifyCopy;
    this.$browserDetect = this.browserDetectCopy;

    try {
      this.properties = JSON.parse(this.properties);
    } catch (error) {
      //do nothing
    }

    let convert = [
      "media_urls",
      "acfAmenities",
      "acfTrack",
      "acfCampsite",
      "acfMedia",
      "acfOther",
      "attachment_urls",
      "markerimage",
      "media",
      "legStats",
      "lastCampStats",
      "nextCampStats"
    ];

    convert.forEach(row => {
      try {
        if (this.properties[row]) {
          this.properties[row] = JSON.parse(this.properties[row]);
        }
      } catch (error) {
        //do nothing
      }
    });

    try {
      this.properties = JSON.parse(JSON.stringify(this.properties));
    } catch (error) {
      //do nothing
    }
    try {
      delete this.properties.legLineString;
    } catch (error) {
      //do nothing
    }

    if (this.isCampsite) {
      this.functions.nav_openTab("main");
    }

    this.ready = true;
  },

  methods: {
    loadMediaGallery() {
      eventBus.$emit("loadMediaDialog", {
        type: {
          prop: "gallery",
          title: "gallery",
          icon: "mdi-image-multiple",
          single: "Gallery"
        },
        title: "gallery",
        markertitle: this.properties.title,
        gallery: this.properties.media_urls
      });
    }
  }
});
</script>

<style lang="scss">
.mapWrapper #mapContainer .mapboxgl-popup-wrapper {
  z-index: 15;
}

.floating-edit-btn {
  position: absolute;
  top: 36px; /* Adjust this as per your preference */
  right: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}

.floating-reset-btn {
  position: absolute;
  bottom: 0; /* Adjust this as per your preference */
  right: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}

.floating-close-btn {
  position: absolute;
  top: 0; /* Adjust this as per your preference */
  right: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}
.floating-fullscreen-btn {
  position: absolute;
  top: 0; /* Adjust this as per your preference */
  left: 0; /* Adjust this as per your preference */
  z-index: 1; /* Ensure it's above the carousel */
}

.v-size--small.v-btn.popupMediaButton {
  background: #ffffff9e !important;
  height: 30px;
  width: 40px;
  min-width: auto;

  &.floating-close-btn {
    border-radius: 0 0 0 5px;
  }
  &.floating-fullscreen-btn {
    border-radius: 0 0 5px 0;
  }
}

.moreInfoButton {
}

.mapboxgl-popup-anchor-bottom {
  margin-bottom: 5px;
}

.mapboxgl-popup-content {
  box-shadow: none;
}
.galPopup,
.galPopup .mapboxgl-popup-content {
  background: transparent;
  padding: 0;
}

.galPopup {
  height: 270px;
}

#vue-popup-content,
.xgalPopup,
.xhhPopupAll,
.xgalPopup {
  height: 450px;
  min-height: 450px;
}

/*
tooltip stuff 
*/
.campTooltipWrapper {
  margin-top: 30px;
}
.campTooltipWrapper .mapboxgl-popup-content {
  border-radius: 4px;
  padding: 0 4px;
  text-align: center;
}

.campTooltipWrapper .mapboxgl-popup-tip {
  display: none;
}

.hhToolTip {
  padding: 0;
  margin: 0;
}

.popupTitle {
  line-height: 1.3;
}

.popupSubTitle {
  font-size: 1rem;
}
.popupSubTitle img {
  height: 21px;
}

.galPopup .mapboxgl-popup-content .v-card {
  min-height: 50px;
  width: 240px;
  width: 360px;
  max-width: 95vw;
}

.isTabletOrMobile {
  .galPopup .mapboxgl-popup-content .v-card {
    width: 230px;
  }

  .campText,
  .clampFourLines,
  .v-card__text,
  .v-card__subtitle,
  .popupSubTitle.v-card__title,
  .popupTitle.v-card__title {
    font-size: 0.75rem;
  }

  .campText,
  .clampFourLines,
  .v-card__text {
    line-height: 1rem;
  }

  .popupTitle.v-card__title {
    font-size: 1rem;
  }

  .v-card__text,
  .v-card__title,
  .v-card__subtitle {
    padding-left: 4px;
  }
  .v-card__text {
    padding-right: 4px;
  }
}
</style>
