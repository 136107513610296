<template>
  <div>
    <v-btn
      block
      color="primary"
      tile
      small
      :title="`Download Attachment '${doc.filename}'`"
      @click="downloadFile()"
      elevation="1"
    >
      <v-icon left>mdi-file-download</v-icon>
      {{ doc.title }}{{ fileType }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "downloadButton",

  components: {},

  data: () => ({}),
  props: {
    doc: Object
  },
  methods: {
    downloadFile() {
      if (this?.doc?.src && this?.doc?.filename) {
        // Create an invisible link element to trigger the download
        const link = document.createElement("a");
        link.href = this.doc.src;
        link.download = this.doc.filename;
        link.target = "_blank"; // Optional: Open in new tab if needed

        // Append to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link element after download starts
        document.body.removeChild(link);
      }
    }
  },
  computed: {
    fileType() {
      try {
        return (
          "." +
          this.doc.filename.split(".")[this.doc.filename.split(".").length - 1]
        );
      } catch (error) {
        //do nothing;
        return "";
      }
    }
  }
};
</script>

<style></style>
