<template>
  <v-card>
    <v-tabs
      class="mainTabs"
      v-model="tab"
      background-color="primary"
      dark
      style="border-radius: 0;"
    >
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.title }}
      </v-tab>

      <v-btn
        v-show="$vuetify.breakpoint.mdAndDown"
        @click="closeMenuRemote"
        class=" ml-auto my-auto"
        icon
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item key="main">
        <CampSiteSideList />
      </v-tab-item>

      <v-tab-item key="poi">
        <shortwalksList />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { eventBus } from "../../main";

import CampSiteSideList from "./../campsiteList/CampSiteSideList.vue";
import shortwalksList from "./../shortwalksList/shortwalksList.vue";

export default {
  name: "sidebar",

  components: { CampSiteSideList, shortwalksList },

  data() {
    return {
      tab: null,
      items: [
        { tab: "main", title: "Jurney" },
        { tab: "poi", title: "Short Walks /Points" }
      ]
    };
  },

  methods: {
    closeMenuRemote() {
      eventBus.$emit("nav_layout_closeMenu");
    }
  },
  mounted() {
    eventBus.$on("nav_openTab", target => {
      if (target == "poi") {
        this.tab = 1;
      } else if (this.tab !== 0) {
        this.tab = 0;
      }
    });
  }
};
</script>
<style>
.mainTabs .v-tab {
}

.isTabletOrMobile {
  .v-slide-group__prev {
    display: none !important;
  }

  .mainTabs .v-tab {
    max-width: 107px;
    font-size: 0.75rem;
    padding: 0;
  }
}
</style>
